// Keeps main / content from collapsing, bottom of foooter at bottom of viewport
// when there's very little content
// @see https://dev.to/domysee/keeping-the-footer-at-the-bottom-with-css-flexbox-5h5f
// (see comments regarding dealing with mobile browser address bars, etc, using dvh)
#mpf-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
}

.mct-l-app,
.mct-l-app__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.mct-l-app__main {
  flex-grow: 1;
  flex-shrink: 0;
}

// May be unnecessary, but just ensures the CH (#root) and footer never shrink
// any of their contents to compensate for main content growing (shouldn't, because
// the container element, #mpf-root, only has a min-height constraint of 100dvh)
.mc-c-footer,
// The root element of the global (consistent) header
#root {
  flex-shrink: 0;
}
