.SpapPlansPage {
  .PrintButton {
    @extend .ds-u-padding-left--1;
  }

  h1 {
    @extend .ds-u-margin--0;
  }

  &__banner {
    @extend %mct-l-flush-to-edge;
    column-gap: var(--spacer-2);
  }

  &__state-selector {
    flex-grow: 1;
    order: 1;
    @media screen and (min-width: $media-width-sm) {
      order: 0;
    }
  }

  &__print-button {
    align-self: flex-start;
    @media screen and (min-width: $media-width-sm) {
      align-self: auto;
    }
  }

  &__content {
    @extend %mct-l-container--pace-pap-spap;
  }

  @media screen and (max-width: $media-width-md) {
    .StateSelector {
      order: 2;
    }

    .PrintButton {
      margin-left: -8px;
    }
  }
}

.SpapPlanCard {
  @extend .ds-u-padding--1;
  @extend .ds-u-lg-padding--4;

  &__sectionHeading {
    @extend .ds-u-text-transform--uppercase;
    font-family: $font-family-rubik;
    font-size: $font-size-md;
    font-weight: 400;
    margin-bottom: $spacer-1;
  }

  &__cardColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $spacer-4;
  }

  &__cardColumnSection {
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  @media print {
    box-shadow: none;
    border: none;
    padding: 0 !important;
    margin-top: 2em !important;
    margin-bottom: 0 !important;
    page-break-inside: avoid;

    &__cardColumn {
      display: inline-block !important;
      width: 50% !important;
      vertical-align: top;
    }

    .ds-u-border-right--1 {
      border: 0 !important;
    }
  }
}
