.planCompare {
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--row;
  width: 100%;

  @media screen and (max-width: $media-width-lg) {
    flex-direction: column !important;
  }

  &__col {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    width: 100%;

    &:not(:first-child) {
      .planCompare__cell--headerRow:not(:first-child) {
        h2 {
          display: none;
        }
      }
    }

    .columnSection {
      .planCompare__cell:nth-child(even),
      .planCompare__cell:only-child {
        @extend .ds-u-fill--gray-lightest;
      }
    }

    @media screen and (max-width: $media-width-lg) {
      border: 1px solid $color-gray-dark;
      display: block !important;
      padding: 16px;
      margin-bottom: 16px;

      .planCompare__cell {
        white-space: nowrap;
      }

      .planCompare__cell--headerRow:not(:first-child) {
        border-right: 1px solid $color-gray-dark !important;
        border-bottom: none !important;

        h2 {
          display: block !important;
          position: absolute;
          z-index: 2;
          white-space: nowrap;
          font-size: 18px;
          color: $color-gray-dark;
          border-bottom: 1px solid $color-gray-dark;
          line-height: 32px;
          margin-left: 32px;
        }
      }

      .planCompare__cell--headerRow {
        white-space: normal !important;

        .spacer {
          display: none;
        }
      }
    }
  }

  &__mobileRow {
    @media screen and (max-width: $media-width-lg) {
      background-color: $color-gray-lightest;
      overflow-x: scroll;
      display: flex;
      flex-direction: row;

      .planCompareCard {
        border: none !important;
        box-shadow: none;
        margin-top: -16px;
        width: 150px;
        background-color: $color-gray-lightest !important;
      }

      .columnSection {
        display: flex;
        margin-top: 32px;

        &__wrap {
          flex-wrap: wrap;
          min-width: 1700px;

          & > div {
            white-space: normal;
            width: 300px;
          }
        }

        .mobileLabServices {
          white-space: normal;
          display: flex;
          flex-wrap: wrap;
          min-width: 950px;

          & > div {
            margin-right: 16px;
            width: 300px;
          }

          .Collapsible__contentInner {
            width: 300px;
          }
        }
      }
    }
  }

  &__headingCol {
    .columnSection {
      .planCompare__cell:nth-child(even),
      .planCompare__cell:only-child {
        @extend .ds-u-fill--gray-lightest;
      }
    }

    @media screen and (max-width: $media-width-lg) {
      display: none !important;
    }
  }

  &__cell {
    @extend .ds-u-border-x--1;
    @extend .mct-u-border--gray-lightest;
    @extend .ds-u-margin--0;
    @extend .ds-u-padding-top--2;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-padding-bottom--0;
    position: relative;

    &--headerRow:not(:first-child) {
      @extend .ds-u-border-x--0;
      @extend .ds-u-border-bottom--1;
      @extend .mct-u-border--gray-dark;
      @extend .ds-u-padding-top--0;
      min-height: 64px;
      position: relative;

      h2 {
        @media screen and (min-width: $media-width-lg) {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
      }

      &.planCompare__cell--fillerRow {
        border: none !important;

        @media screen and (max-width: $media-width-lg) {
          border-right: 1px solid $color-gray-dark !important;
        }
      }
    }

    .DrugCosts__list {
      @media screen and (max-width: $media-width-lg) {
        display: flex;

        & > div {
          margin-right: 32px;
        }
      }
    }
  }

  &__checkItem {
    line-height: $font-size-xl;

    label {
      @extend .ds-u-margin-left--1;
      display: inline;
    }
  }

  .currentPlanCompareCardHeader {
    text-align: center;
    position: absolute;
    top: -30px;
    padding: 0 16px;
    right: 0;
    left: 0;
  }

  &__mobileSummary {
    display: none !important;

    @media screen and (max-width: $media-width-lg) {
      display: block !important;

      .planCompareCard {
        min-height: auto;
        border: none !important;
        box-shadow: none;

        &__closeButton {
          display: block !important;
        }

        &__detailsHeader {
          display: block !important;
        }

        &__actionSection {
          display: flex !important;
          flex-direction: row !important;
          justify-content: flex-start;
          margin-top: 20px;

          .EnrollTransitionDialog {
            width: auto;
          }

          button {
            margin: 8px 16px 8px 0;
          }
        }
      }
    }
  }

  &__benefitList {
    @extend .ds-c-list--bare;
    @extend .ds-u-display--block;

    @media screen and (max-width: $media-width-lg) {
      column-count: 2;
    }
  }
}

.planCompare:first-child .planCompare__cell {
  @extend .ds-u-border-left--0;
}

.planCompare__col:last-child .planCompare__cell {
  @extend .ds-u-border-right--0;
}

.planCompare__col .planCompare__cell--headerRow:last-of-type {
  @extend .ds-u-border-bottom--0;
}

.planComparisonSummary {
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-justify-content--between;
  @extend .ds-u-align-items--center;
  @extend .ds-u-padding--4;
  background-color: $color-secondary-lightest;
  border-radius: 5px;
  box-shadow: 4px 4px 0px 0px $color-gray-lighter;
  box-sizing: border-box;
  height: 100%;

  h1 {
    width: 100%;
  }

  &__backBtn {
    @extend .ds-u-padding-y--1;
    background-color: $color-secondary-lightest;
    width: 100%;
  }
}

.YearOverYear {
  &__planBadge {
    background-color: #e1f3f2;
    @extend .ds-u-padding-y--1;
    @extend .ds-u-padding-x--3;
    @extend .ds-u-color--base;
  }

  &__navigation {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;

    padding: $spacer-3 $spacer-2;
    border-bottom: $color-gray-lighter solid 1px;
    @extend .ds-u-margin-top--0;

    @media screen and (min-width: $media-width-sm) {
      padding: $spacer-3 $spacer-7;
    }

    li {
      @extend .ds-u-margin-right--4;
      list-style: none;
      @extend .ds-u-display--flex;
      @extend .ds-u-align-items--center;
    }
  }

  &__nextSteps {
    background-color: #e8f2ff;
    @extend .ds-u-padding--3;
    @extend .ds-u-margin-x--3;
  }

  &__nextStepExploreButton {
    @extend .ds-c-button--solid;
    @extend .ds-c-button--alternate;
    @extend .ds-u-margin-top--2;
  }

  &__nextStepChoice {
    @media screen and (min-width: $media-width-md) {
      width: 50%;
    }

    &:nth-child(1) {
      @extend .ds-u-md-padding-right--3;
      @extend .ds-u-md-padding-bottom--0;
      @extend .ds-u-padding-bottom--3;
    }

    &:nth-child(2) {
      @extend .ds-u-md-padding-left--3;
      @extend .ds-u-padding-top--3;
      @extend .ds-u-md-padding-top--0;
      @extend .ds-u-border-top--1;

      @media screen and (min-width: $media-width-md) {
        border-left: 1px solid $color-gray-lighter;
        border-top: none !important;
      }
    }
  }

  &__reminderIcon {
    width: 29px;
    height: 40px;
  }
}

.headerRow {
  @extend .ds-l-row;
  @extend .ds-u-fill--secondary-lightest;
  @extend .ds-u-margin-y--2;
  @extend .ds-u-padding-x--2;
  font-size: 22px;
}
