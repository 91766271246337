// Taken from WDS 5.5.4 (core DS 3.5.4)
// In no particular order for now

// Teal/Primary
$teal-700: #0f4b42;
$teal-500: #146a5d;
$teal-300: #409e90;
$teal-100: #e0f2f1;

$color-primary: $teal-500;
$color-primary-dark: $teal-700;
$color-primary-darker: darken($color-primary, 10%);
$color-primary-darkest: darken($color-primary, 20%);
$color-primary-light: $teal-300;
$color-primary-lightest: $teal-100;

// Focus
$color-focus-light: #ffffff;
$color-focus-dark: #c97532;

// Blue/Primary Alt
$blue-700: #12284d;
$blue-500: #1e3c70;
$blue-300: #4f6b9b;
$blue-100: #e8f1ff;

$color-secondary: $blue-500;
$color-secondary-dark: $blue-700;
$color-secondary-darker: darken($color-secondary, 10%);
$color-secondary-darkest: darken($color-secondary, 20%);
$color-secondary-light: $blue-300;
$color-secondary-lightest: $blue-100;

$color-secondary: $blue-500;
$color-secondary-dark: darken($color-secondary, 60%);
$color-secondary-darker: $color-secondary-dark;
$color-secondary-darkest: $color-secondary-dark;
$color-secondary-light: $blue-300;
$color-secondary-lightest: $blue-100;

// Black
$black-700: #1f252d;
$black-500: #323a45;
$black-300: #5b616b;
$black-200: #72777f;
$black-100: #d6d7d9;
$black-050: #f3f3f3;

$color-gray-darker: $black-700;
$color-gray-dark: $black-500;
$color-gray: $black-300;
$color-gray-light: $black-200;
$color-gray-lighter: $black-100;
$color-gray-lightest: $black-050;

// Green/Success
$green-500: #12890e;
$green-700: #0d5e0b;
$green-300: #94bfa2;
$green-100: #e7f4e3;

$color-green: $green-500;
$color-green-dark: $green-700;
$color-green-lighter: $green-300;
$color-green-lightest: $green-100;

$color-success: $green-500;
$color-success-dark: $green-700;
$color-success-light: $green-300;
$color-success-lightest: $green-100;

// Yellow/Warn
$yellow-500: #f8c41f;
$yellow-700: #f1ad15;
$yellow-300: #fad980;
$yellow-100: #fff1d2;

$color-warn: $yellow-500;
$color-warn-dark: $yellow-700;
$color-warn-light: $yellow-300;
$color-warn-lightest: $yellow-100;

// Red/Error
$red-500: #b20000;
$red-700: #8d0000;
$red-300: #e59393;
$red-100: #f8dddd;

$color-error: $red-500;
$color-error-dark: $red-700;
$color-error-light: $red-300;
$color-error-lightest: $red-100;

// Visited link color
$color-visited: #701e3c;

$color-base: $color-gray-dark;
$color-background-inverse: $color-primary;

$card-box-shadow: 0 2px 3px 0 rgba(50, 50, 50, 0.23);

// override css variables
:root {
  --color-primary: #{$color-primary};
  --color-primary-dark: #{$color-primary-dark};
  --color-primary-darker: #{$color-primary-darker};
  --color-primary-darkest: #{$color-primary-darkest};
  --color-primary-light: #{$color-primary-light};
  --color-primary-lightest: #{$color-primary-lightest};

  --color-secondary: #{$color-secondary};
  --color-secondary-dark: #{$color-secondary-dark};
  --color-secondary-darker: #{$color-secondary-dark};
  --color-secondary-darkest: #{$color-secondary-dark};
  --color-secondary-light: #{$color-secondary-light};
  --color-secondary-lightest: #{$color-secondary-lightest};

  --color-gray-darker: #{$color-gray-darker};
  --color-gray-dark: #{$color-gray-dark};
  --color-gray: #{$color-gray};
  --color-gray-light: #{$color-gray-light};
  --color-gray-lighter: #{$color-gray-lighter};
  --color-gray-lightest: #{$color-gray-lightest};

  --color-green: #{$color-green};
  --color-green-dark: #{$color-green-dark};
  --color-green-lighter: #{$color-green-lighter};
  --color-green-lightest: #{$color-green-lightest};

  --color-success: #{$color-success};
  --color-success-dark: #{$color-success-dark};
  --color-success-light: #{$color-success-light};
  --color-success-lightest: #{$color-success-lightest};

  --color-warn: #{$color-warn};
  --color-warn-dark: #{$color-warn-dark};
  --color-warn-light: #{$color-warn-light};
  --color-warn-lightest: #{$color-warn-lightest};

  --color-error: #{$color-error};
  --color-error-dark: #{$color-error-dark};
  --color-error-light: #{$color-error-light};
  --color-error-lightest: #{$color-error-lightest};

  --color-base: #{$color-base};
  --color-background-inverse: #{$color-background-inverse};
}
