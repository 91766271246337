// Flex helpers
$_align-items-values: (
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline,
  stretch: stretch,
);

@each $name in map-keys($_align-items-values) {
  $value: map-get($_align-items-values, $name);
  // Example: .mct-u-align-self--start
  .mct-u-align-self--#{$name} {
    // Example: align-self: flex-start
    align-self: $value !important;
  }
}

.mct-u-flex-shrink--0 {
  flex-shrink: 0;
}

.mct-u-flex-grow--1 {
  flex-grow: 1 !important;
}

.mct-u-display--inline-flex {
  display: inline-flex;
}
