.mct-c-current-location {
  @extend .ds-u-margin-top--1;

  // button padding (8) + width of spinner (14) + right padding (8) = 30
  $button-padding: 30px;

  &__buttons {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-sm-flex-direction--row;
    @extend .ds-u-flex-wrap--nowrap;
    @extend .ds-u-align-items--stretch;
    @extend .ds-u-sm-align-items--start;

    gap: $spacer-1;
  }

  &__use-current-location-button {
    @extend .ds-c-button--small;
    padding-right: $button-padding;
  }

  &__use-home-address-button {
    @extend .ds-c-button--small;
    padding-right: $button-padding;
    padding-left: $button-padding;
  }
}
