.mct-c-ntm-page-resources {
  @extend .ds-u-margin-top--1;

  display: grid;
  grid-gap: $spacer-3;
  grid-template-columns: 1fr;

  @media screen and (min-width: $media-width-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: $media-width-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: $media-width-xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}
