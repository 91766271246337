.SelectPharmaciesPage {
  $root: &;
  $pharmacy-list-width: 800px;
  $pharmacy-number-info-gap: $spacer-1;
  $pharmacy-number-column-width-sm: 40px;
  $pharmacy-number-column-width-lg: 75px;
  $pharmacy-card-gap: $spacer-3;
  $pharmacy-info-column-width: calc(400px - #{$pharmacy-card-gap});

  &__search {
    margin: 0 30px;
  }

  &__backButton {
    @extend .ds-u-text-decoration--none;
    @extend .ds-c-button--transparent;
    @extend .ds-u-display--inline-block;
    margin-top: 40px !important;
    .ds-c-icon--arrow {
      font-size: 11px;
    }
  }

  &__searchButton {
    @extend .ds-u-margin-x--0;
    @extend .ds-u-margin-y--05;

    &--cmas-pharmacy {
      @media screen and (min-width: $media-width-lg) {
        margin-top: calc(#{$spacer-1} * 9) !important;
      }
    }
  }

  &__distanceFilter {
    background-color: $color-gray-lightest;
    @extend .ds-u-padding-y--3;
    width: 100%;
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    border-bottom: 1px solid $color-gray-lighter;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-md-padding-x--7;
    box-sizing: border-box;

    .ds-c-choice-wrapper {
      .ds-c-label {
        white-space: nowrap;
      }
    }
  }

  &__mobileFilterActions {
    @extend .ds-u-border-top--1;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--end;
    @extend .ds-u-margin-top--7;
    @extend .ds-u-padding--3;
  }

  &__resultsContainer {
    & > * {
      box-sizing: border-box;
    }

    margin: 0 -16px;
    @media screen and (min-width: $media-width-md) {
      margin: 0 -56px;
    }
    @extend .ds-u-margin-top--4;
  }

  &__distanceDropdown {
    label {
      @extend .ds-u-margin--0;
    }
  }

  &__csrMapButtonContainer {
    @extend .ds-u-lg-display--flex;
    @extend .ds-u-justify-content--end;
    flex-grow: 1;
  }

  &__results {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column-reverse;
    @extend .ds-u-lg-flex-direction--row;
  }

  &__pharmacyOptionsContainer {
    @media screen and (min-width: $media-width-lg) {
      width: $pharmacy-list-width;
    }
  }

  &__alertMessage {
    @extend .ds-u-margin-x--2;
    @extend .ds-u-margin-top--3;
    @extend .ds-u-margin-bottom--1;
  }

  &__grayBottomBorder {
    border-bottom: 1px solid $color-gray-lighter;
  }

  &__pharmacies {
    @extend .ds-c-list--bare;

    li:not(:last-child) {
      border-bottom: 1px solid $color-gray-lighter;
    }
  }

  &__desc {
    @extend .ds-u-margin--0;

    @media screen and (min-width: $media-width-lg) {
      width: $pharmacy-list-width;
      max-width: $pharmacy-list-width;
    }
  }

  &__pharmaciesMap {
    height: 300px;

    @media screen and (min-width: $media-width-lg) {
      // The height value will be overridden in JS, based on a calc that subtracts
      // the height of the sticky footer (the selected pharmacies list)
      height: 100vh;
      position: sticky;
      top: 0;
    }

    @media screen and (min-width: $media-width-lg) {
      width: calc(100vw - #{$pharmacy-list-width});
    }
  }

  &__pharmacyCard {
    @extend .ds-u-padding-left--2;
    @extend .ds-u-md-padding-left--7;
    @extend .ds-u-padding-y--4;
    @extend .ds-u-lg-display--flex;
    @extend .ds-u-md-padding-right--3;
    gap: $pharmacy-card-gap;
    position: relative;

    &__status-container {
      font-weight: 500;
    }
    .ds-c-skip-nav {
      top: unset;
      bottom: -#{$spacer-1};
      left: -1000px;
      width: calc(100% - #{$spacer-4});

      &:focus {
        left: 0;
      }
    }
  }

  &__pharmacyCardRow {
    @extend .ds-u-display--flex;
    gap: $spacer-1;
  }

  &__pharmacyCardCol {
    &--one {
      $width: calc(
        #{$pharmacy-number-column-width-sm} - #{$pharmacy-number-info-gap}
      );
      width: $width;
      min-width: $width;
      max-width: $width;
      @media screen and (min-width: $media-width-lg) {
        $width: calc(
          #{$pharmacy-number-column-width-lg} - #{$pharmacy-number-info-gap}
        );
        width: $width;
        min-width: $width;
        max-width: $width;
      }
    }
    &--two {
      &#{$root}__pharmacyInfoCol {
        @media screen and (min-width: $media-width-lg) {
          width: $pharmacy-info-column-width;
          min-width: $pharmacy-info-column-width;
          max-width: $pharmacy-info-column-width;
        }
      }
    }
  }

  &__pharmacyCardInfoContainer {
    @extend .ds-u-display--flex;
    flex-direction: column;
    gap: $pharmacy-number-info-gap;
  }

  &__addPharmacyCostsContainer {
    align-content: center;
    @extend .ds-u-display--flex;
    @extend .ds-u-lg-padding-left--3;
    @media screen and (min-width: $media-width-lg) {
      border-left: 1px solid $color-gray-lighter;
    }
  }

  &__distance {
    color: var(--color-gray);
    &--sm {
      @extend .ds-u-display--block;
      @extend .ds-u-md-display--none;
    }
    &--lg {
      @extend .ds-u-display--none;
      @extend .ds-u-md-display--block;
    }
  }

  &__addPharmacy {
    @extend .ds-u-align-items--start;
    @extend .ds-u-lg-align-items--center;
    @extend .ds-u-display--flex;
    @extend .ds-u-margin-top--2;
    @extend .ds-u-lg-margin-top--0;
    @extend .ds-u-lg-margin-left--0;
    &--with-costs {
      gap: $spacer-1 0;
      @extend .ds-u-flex-direction--column;
      @extend .ds-u-justify-content--between;
    }
  }

  &__pharmacyCardHeader {
    color: $color-primary;
    @extend .ds-text-heading--xl;
    @extend .ds-u-margin-bottom--0;
  }

  &__pharmaciesOption {
    label {
      @extend .ds-u-margin--0;
    }
  }
}

.selectPharmacies__pagination {
  @extend .pagination;
  @extend .ds-u-padding--1;
  @extend .ds-u-display--flex;
  @extend .ds-u-md-justify-content--center;
  @extend .ds-u-justify-content--around;

  & > button:first-child {
    @extend .ds-u-padding-left--0;
  }

  & > button:last-child {
    @extend .ds-u-padding-right--0;
  }

  & > .page-number:first-child {
    margin: -4px;
  }

  @media screen and (max-width: $media-width-sm) {
    .ds-c-button {
      padding: 8px !important;
    }
  }
}
