:root {
  /* max width is specified on ticket: MCT-6717 */
  --landing-page-elements-base-max-width: 1200px;
}

.mct-p-landing-page {
  @extend .ds-u-padding--3;
}

.mct-c-landing-page-hero,
.mct-c-landing-page-main,
.mct-c-landing-page-footer {
  @media screen and (min-width: $media-width-lg) {
    margin: 0 auto;
  }
}

.mct-c-landing-page-hero {
  @media screen and (min-width: $media-width-lg) {
    display: grid;
    grid-template-columns: 67% 33%;
    /* The hero has no padding or border, so max-width is just set on the content*/
    max-width: var(--landing-page-elements-base-max-width);
  }

  &--left-col {
    @media screen and (min-width: $media-width-lg) {
      display: flex;
      flex-flow: column nowrap;
    }
  }

  &__header-group {
    @media screen and (min-width: $media-width-lg) {
      flex-grow: 2;
      display: flex;
      align-items: center;
    }
    &--ntm {
      @extend .ds-u-margin-bottom--5;
      @media screen and (min-width: $media-width-lg) {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  &--right-col {
    display: none;

    @media screen and (min-width: $media-width-lg) {
      display: flex;
      align-items: flex-end;
    }
  }

  &__image {
    max-width: 315px;
    width: 100%;
  }

  &__get-started {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-padding-bottom--2;
    @extend .ds-u-lg-padding-bottom--4;
  }
}

.mct-c-landing-page-main {
  @extend .ds-u-border--1;
  @extend .ds-u-padding-y--3;
  $border-radius: 19px;

  border-radius: $border-radius;
  border-color: $color-gray-lighter;
  box-shadow: 0 2px 3px 0px rgba(51 51 51 / 15%);

  @media screen and (min-width: $media-width-lg) {
    padding: $spacer-5 !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacer-3 $spacer-5;
    /* border-box calculation = base max-width plus padding x2 + border x2 */
    max-width: calc(
      var(--landing-page-elements-base-max-width) + (#{$spacer-5} * 2) +
        (1px * 2)
    );
  }

  &.mct-c-landing-page-main--v2 {
    @media screen and (min-width: $media-width-lg) {
      grid-template-columns: 53% 47%;
      gap: $spacer-5 $spacer-5;
    }
  }

  &__heading {
    @extend .ds-text-heading--lg;
    @extend .ds-u-padding-x--3;

    @media screen and (min-width: $media-width-lg) {
      margin-bottom: 0;
      grid-column: span 2;
      text-align: center;
      font-size: $font-size-xl;
    }
  }

  &__login {
    @extend .ds-u-padding--3;
    background-color: $color-secondary-lightest;

    @media screen and (min-width: $media-width-lg) {
      grid-column: span 1;
      border-radius: $border-radius;
    }
  }

  &__coverage {
    @extend .ds-u-padding--3;

    @media screen and (min-width: $media-width-lg) {
      grid-column: span 1;
      padding-bottom: 0 !important;
    }

    &--no-login {
      @extend .ds-u-display--flex;
      @extend .ds-u-flex-direction--column;
      @extend .ds-u-align-items--center;

      @media screen and (min-width: $media-width-lg) {
        grid-column: span 2;
        padding-bottom: 0 !important;
      }
    }

    &.mct-c-landing-page-main__coverage--v2 {
      @media screen and (min-width: $media-width-lg) {
        padding-top: $spacer-1 !important;
      }
    }
  }

  .mct-c-badge {
    &__content {
      color: #212121;
    }

    &__icon {
      color: $color-secondary;
    }
  }
}

.mct-c-landing-page-footer {
  @extend .ds-u-padding-left--3;
  @extend .ds-u-padding-bottom--4;

  @media screen and (min-width: $media-width-lg) {
    padding-left: $spacer-8 !important;
    padding-right: $spacer-8 !important;
    /* border-box calculation = base max-width plus padding x2 (no borders) */
    max-width: calc(
      var(--landing-page-elements-base-max-width) + (#{$spacer-8} * 2)
    );
  }
}
