.SanctionedPlansHeading {
  @extend .ds-u-display--flex;
  @extend .ds-u-justify-content--between;
  @extend .ds-u-md-align-items--center;
  @extend .ds-u-margin-top--4;
  @extend .ds-u-margin-bottom--3;
  @extend .ds-u-margin-x--6;
}

.ShowingTotalSanctioned {
  @extend .ds-u-font-weight--semibold;
  @extend .ds-u-margin-bottom--2;
  margin-left: -48px;
}
