.StarRating {
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-wrap--wrap;
  @extend .ds-u-align-items--start;

  &__stars {
    @extend .ds-u-display--flex;
    flex-wrap: nowrap;
    align-self: center;
  }

  &__performer {
    @extend .ds-u-display--flex;
    width: 100%;
    font-size: $font-size-md !important;

    @media screen and (min-width: $media-width-sm) {
      width: 50%;
    }

    &--full {
      width: 100%;
    }
  }

  &__title {
    @extend .ds-u-font-size--sm;
    @extend .ds-u-margin-right--1;
    white-space: nowrap;
  }

  &__performerIcon {
    height: 26px;
    width: 26px;
    margin-right: 5px;
  }

  &__explanationContainer {
    @extend .ds-u-font-size--sm;

    & > button {
      white-space: nowrap;
    }
  }

  &__message {
    align-self: flex-end;
  }

  & > span:not(.StarRating__stars) {
    white-space: normal;

    @media screen and (min-width: $media-width-sm) {
      white-space: nowrap;
    }
  }
}

// Override of element-targeting declaration. The original intention is unclear.
// Within the table, stars content looks like it should always be allowed to
// wrap.
.PlanComparePage__table {
  .StarRating {
    & > span:not(.StarRating__stars) {
      @media screen and (min-width: $media-width-sm) {
        white-space: normal;
      }
    }
  }
}
