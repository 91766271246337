#summary-page-navigation {
  padding: $spacer-3 $spacer-2;
  border-bottom: $color-gray-lighter solid 1px;
  @media screen and (min-width: $media-width-sm) {
    padding: $spacer-3 $spacer-7;
  }
}

.mct-p-summary-page {
  .mct-c-sub-section {
    &__action-button {
      @extend .mct-hide-print;
      display: flex;
      justify-content: center;
      margin: $spacer-3 0;
      @media screen and (min-width: $media-width-sm) {
        margin: $spacer-5 0;
        display: inline-block;
      }
    }
    &:last-child {
      margin-bottom: $spacer-5;
    }
    &:nth-last-child(2) {
      .mct-c-sub-section__print-divider {
        @media print {
          display: none;
        }
      }
    }
    &__shop-for-coverage {
      @media print {
        display: none;
      }
    }
  }

  .mct-c-have-other-medicare-coverage {
    margin: $spacer-4 0;
    @media screen and (max-width: $media-width-sm) {
      margin: $spacer-1 0 64px 0;
    }
    &__header {
      margin-bottom: 0;
    }
  }
}

.mct-c-summary-page {
  $c: &;
  &-alert {
    // Match extra-help and shop cards width plus padding, at lower breakpoints
    @media screen and (min-width: $media-width-sm) {
      max-width: calc(
        #{$summary-page-card-medium--width-md} + (#{$spacer-3} * 2)
      );
    }
    @media screen and (min-width: $media-width-md) {
      max-width: calc(
        #{$summary-page-card-medium--width-lg} + (#{$spacer-3} * 2)
      );
    }
    // Width taken from Sketch design
    @media screen and (min-width: $media-width-lg) {
      max-width: 650px;
    }
    &--no-drugs {
      @media screen and (min-width: $media-width-sm) {
        max-width: 100%;
      }
      @media screen and (min-width: $media-width-lg) {
        max-width: 650px;
      }
    }
    + #{$c}__cards-container {
      margin-top: $spacer-3;
    }
  }
  &-bedap-alert {
    @extend %mct-c-summary-page-card--medium;
    @media print {
      display: flex;
      padding: $spacer-3 !important;
      width: auto;
    }
  }

  &__ntm-alert {
    border-left: $spacer-1 solid var(--color-secondary) !important;
    border-right: 1px solid var(--color-gray-lighter) !important;
    border-top: 1px solid var(--color-gray-lighter) !important;
    border-bottom: 1px solid var(--color-gray-lighter) !important;
    @extend .ds-u-fill--white;
    @extend .ds-u-padding--0;

    .ds-c-alert__body {
      @extend .ds-u-margin--0;
      @extend .ds-u-padding--0;
    }

    .ds-c-alert__header {
      background-color: var(--color-gray-lightest);
      border-bottom: 1px solid var(--color-gray-lighter);
      @extend .ds-u-padding-left--3;
      @extend .ds-u-padding-right--2;
      @extend .ds-u-padding-y--2;

      @media screen and (min-width: $media-width-md) {
        padding-left: calc($spacer-1 * 8) !important;
      }
    }

    .mct-c-alert__body {
      @extend .ds-u-padding-left--3;
      @extend .ds-u-padding-right--2;
      @extend .ds-u-padding-y--2;
    }

    &--warn {
      border-left: $spacer-1 solid var(--color-warn) !important;
      border-right: 1px solid var(--color-warn-light) !important;
      border-top: 1px solid var(--color-warn-light) !important;
      border-bottom: 1px solid var(--color-warn-light) !important;

      .ds-c-alert__header {
        background-color: var(--color-warn-lightest);
        border-bottom: 1px solid var(--color-warn-light);
      }
    }
  }
}
