/**
 * For upgrading to DS 6.0.5, we are doing custom imports inside of a vendor
 * directory, which allows our overrides to work again. This will become moot
 * when we upgrade to v8+, which switches over to CSS custom properties. Be aware
 * that more changes will need to be made to make the import structure work correctly
 * again in the future
 */
// @import "./variable-overrides"; // Must be imported before DS
// @import "@cmsgov/ds-medicare-gov/dist/scss";
@import "./vendor/index";
@import "./settings/index";
@import "./utilities/index";
@import "./overrides/index";
@import "~animate.css/animate.min.css";
@import "~spinkit/spinkit.min.css";
@import "~react-toastify/scss/main";
@import "./base/index";
@import "./components/index";
@import "./pages/index";

// Hack to prevent Optimizely from messing up page flow
*[style="height: 4px;"] {
  height: auto !important;
}
