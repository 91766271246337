.SearchResults {
  &__blueLink {
    color: $color-secondary !important;
  }

  &__rightactions {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
  }

  &__totalResults {
    @extend .ds-l-col;
    @extend .ds-u-padding-x--0;
    @extend .ds-u-display--none;
    @extend .ds-u-lg-display--block;
    color: $color-gray-dark;
  }

  &__totalAndSortContainer {
    @extend .ds-u-lg-display--flex;
    @extend .ds-u-lg-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-margin-bottom--1;
  }

  &__tellMeMore {
    @extend .ds-u-font-size--sm;
    @extend .ds-u-lg-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-md-padding-x--7;
    @extend .ds-u-border-bottom--2;

    .ds-c-help-drawer__toggle {
      @extend .ds-u-lg-margin-top--0;
      @extend .ds-c-button--small;
      @extend .ds-u-padding-left--0;
    }
  }

  &__footer {
    @extend .ds-l-col;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-md-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-flex-wrap--wrap;
    @extend .mct-hide-print;
  }
  &__coachmark {
    max-width: none;
    margin-top: $spacer-3;
    &.mct-c-alert-message {
      .mct-c-alert-message__body {
        max-width: 125ex;
        p:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

.ResultsList {
  padding: 0;
}

.pageCountSummary {
  color: $color-primary;
}

.pagination {
  padding: 8px 16px;

  button {
    border: 1px solid $color-secondary;
    background-color: $color-white;
    color: $color-secondary;
    min-height: 30px;
    min-width: 30px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }

  &__numberButton {
    @extend .ds-c-button;
    @extend .ds-u-padding--0;
    @extend .ds-u-border--1;
    @extend .ds-u-fill--white;
    @extend .ds-u-font-size--sm;
    color: $color-secondary;
    border-color: $color-white !important;

    &--active {
      background-color: $color-secondary !important;
      @extend .ds-u-color--white;
    }

    &:hover {
      border-color: $color-secondary !important;
    }
  }
}

.prev-button {
  text-align: right;
}

.pagination .pagination-item {
  min-height: 20px;
  min-width: 45px;
  @extend .ds-u-text-align--center;
}

.pagination .pagination-item a {
  color: $color-black;
  padding: 8px 16px;
  text-decoration: none;
  border: solid 1px $color-white;

  &:hover:not(.active) {
    border: 1px solid $color-secondary;
  }

  &:visited {
    text-decoration: none;
  }
}

.page-link-active {
  color: $color-white !important;
  background-color: $color-secondary;
  border-radius: 50%;
}

.page-link-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.current-plan-toggle {
  @extend .ds-u-padding-right--1;
  color: $color-primary-light;
  font-family: $font-family-montserrat;
  font-weight: 800;

  i {
    color: $color-black;
    margin-left: 10px;
  }
}

.current-plan-highlight {
  background-color: $color-primary-lightest !important;
}
