.mct-c-icon {
  &--interchangeable-biologics {
    height: 23px !important;
    path {
      fill: $color-success;
    }
    width: 24px !important;
  }
  &--print {
    // for specificity
    &.ds-c-icon {
      // From FontAwesome stylesheets, for print icon lg
      font-size: 1.33333em;
      line-height: 0.75em;
      vertical-align: -0.225em;
    }
  }
  &--variant-semibold {
    path {
      stroke-width: 6%;
    }
  }
  &--variant-bold {
    path {
      stroke-width: 8%;
    }
  }
  &--variant-xs {
    font-size: calc(#{$base-font-size} / 2);
  }
}
