.StickyTray {
  position: sticky;
  bottom: 0px;
  z-index: 100;
  font-family: $font-family-montserrat;

  @media screen and (max-width: $media-width-lg) {
    position: fixed !important;
    left: 0;
    right: 0;

    &.closed {
      position: sticky !important;
    }
  }

  &.js-is-stuck {
    position: relative !important;
    left: 0;
    right: 0;
  }

  &.js-is-sticky {
    left: 0;
    right: 0;
    width: auto;
  }

  &.closed {
    .StickyTray__heading {
      @media screen and (max-width: $media-width-lg) {
        display: none !important;
      }
    }

    .StickyTray__mobileOpenButton,
    .StickyTray__planCompareMobileOpenButton {
      @media screen and (max-width: $media-width-lg) {
        display: inline-block;
      }
    }
  }

  &__body {
    @extend .ds-u-fill--secondary;
  }

  &__content {
    @extend .ds-u-padding--3;
    @extend .ds-u-color--white;
  }

  &__buttonContainer,
  &__planCompareButtonContainer {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
  }

  &__buttonContainer {
    @media screen and (max-width: $media-width-lg) {
      flex-direction: column;
    }
  }

  &__planCompareButtonContainer {
    gap: $spacer-3;

    &.open {
      @media screen and (max-width: $media-width-lg) {
        flex-direction: column;
      }
    }
  }

  &__header {
    @extend .ds-u-color--white;

    @media screen and (max-width: $media-width-lg) {
      width: 100%;
    }
  }

  &__heading {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-font-size--2xl;
    @extend .ds-u-lg-font-size--base;

    @media screen and (max-width: $media-width-lg) {
      font-weight: 600;
      margin: 0;
    }
  }

  &__action {
    @extend .mct-u-width--full;
    @extend .mct-u-lg-width--auto;
    @extend .ds-u-sans;

    @media screen and (min-width: $media-width-lg) {
      font-size: 16px;
      padding: 8px 24px;
    }
  }

  &__list {
    @extend .ds-c-list;
    @extend .ds-c-list--bare;
    @extend .ds-u-color--white;
    @extend .ds-u-margin-top--3;
    @extend .ds-u-margin-bottom--4;
    @extend .ds-u-lg-margin-y--0;
    @extend .ds-u-lg-margin-x--3;
    @extend .ds-u-flex-direction--row;
    gap: 0;
    flex-grow: 4;
    justify-content: space-evenly;
    max-height: 280px;
    overflow: auto;
    transition: max-height 0.5s ease-in-out;

    @media screen and (max-width: $media-width-lg) {
      display: block;
      width: 100%;
    }

    &.closed {
      @extend .ds-u-margin-y--0;

      @media screen and (max-width: $media-width-lg) {
        display: none;
      }
    }
  }

  &__list_item {
    @extend .ds-u-padding--0;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    border: 2px solid $color-secondary-light;
    border-radius: 4px;
    flex-basis: 33%;

    .pharmacy-network-status__icon {
      path {
        fill: $color-white !important;
      }

      &.ds-c-badge--success {
        @extend .ds-u-fill--background;
        @extend .ds-u-color--secondary;
      }
    }

    &:not(:last-child) {
      @extend .ds-u-margin-bottom--3;
      @extend .ds-u-lg-margin-bottom--0;
      @extend .ds-u-margin-right--0;
      @extend .ds-u-lg-margin-right--2;
    }
  }

  &ListItem {
    display: grid !important;
    grid-template-areas:
      "name removeButton"
      "label label";
    &__name {
      align-self: stretch;
      grid-area: name;
      @extend .ds-u-font-weight--bold;
      padding: $spacer-2;
      padding-right: 0;
    }
    &__label {
      align-self: flex-end;
      grid-area: label;
      padding: 0 $spacer-1 $spacer-2 $spacer-2;
    }
    &__remove {
      grid-area: removeButton;
    }
  }

  &__remove {
    @extend .ds-u-text-decoration--none;
    @extend .ds-u-padding-top--1;
    @extend .ds-u-padding-right--1;
    @extend .ds-u-padding-left--2;
    @extend .ds-u-padding-bottom--2;
    align-self: flex-start;
    min-width: 40px; //IE11 fix
  }

  &__list_item_empty {
    background-color: rgba($color-secondary-light, 0.3);
    min-height: auto;

    @media screen and (max-width: $media-width-lg) {
      min-height: 60px;
    }
  }

  &__mobileCloseButtonContainer {
    @extend .ds-u-text-align--center;
    display: none;

    button {
      @extend .ds-u-padding-y--2;
      @extend .ds-u-padding-right--1;
      @extend .ds-u-padding-left--3;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    @media screen and (max-width: $media-width-lg) {
      display: inline-block;
    }
  }

  &__mobileOpenButton,
  &__planCompareMobileOpenButton {
    @extend .ds-u-sans;
    border: 1px solid $color-white !important;
    display: none;
    width: 100%;
  }

  &__mobileOpenButton {
    @extend .ds-u-margin-bottom--4;
  }

  &__toast {
    max-width: 75vw;
    position: relative;
    width: 360px;
    & > .Toastify__toast > .Toastify__close-button {
      display: none;
    }
  }

  &__background {
    @extend .ds-u-fill--gray-light;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    transition: opacity 0.5s;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    @media screen and (min-width: $media-width-lg) {
      display: none;
    }
  }

  &.not-sticky-on-mobile {
    @media screen and (max-width: $media-width-lg) {
      position: relative !important;
    }

    .closed {
      @media screen and (max-width: $media-width-lg) {
        position: relative !important;
      }
    }
  }

  .Toastify {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;
  }
}

.no-scroll-on-mobile {
  @media screen and (max-width: $media-width-lg) {
    overflow: hidden;
  }
}
