.PSContainer {
  @extend .ds-l-row;

  &__left {
    @extend .ds-l-xl-col--8;
    @extend .ds-l-col--12;
    @extend .ds-u-justify-content--start;

    @media screen and (max-width: $media-width-lg) {
      order: 1;
    }
  }

  &__right {
    @extend .ds-l-col;
    @extend .ds-u-margin-x--0;
    @extend .ds-u-margin-bottom--2;
    @extend .ds-u-lg-margin-left--2;
    @extend .ds-u-lg-margin-bottom--0;

    @media screen and (max-width: $media-width-lg) {
      flex-basis: auto;
      order: 0;
    }
  }
}

.PharmacySelection {
  &__summary {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-margin-bottom--2;

    &__text {
      @extend .ds-text-heading--xl;
      @extend .ds-u-margin--0;
    }

    button {
      color: $color-gray-dark !important;
    }

    @media screen and (max-width: $media-width-md) {
      align-items: flex-start !important;
    }
  }

  &__list {
    @extend .ds-u-padding--0;
    @extend .ds-u-margin-y--0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (max-width: $media-width-sm) {
      flex-direction: column !important;
    }
  }

  &__map {
    @extend .ds-u-margin-left--1;

    @media screen and (max-width: $media-width-lg) {
      margin: 0 !important;
    }
  }
}

.PharmacySelectionWarning {
  @extend .ds-u-fill--secondary-lightest;
  @extend .ds-u-padding--2;
}

.PharmacySelectionFooter {
  .StickyTray__list_item {
    @extend .ds-u-text-transform--capitalize;
  }

  .StickyTray__heading {
    @media screen and (min-width: $media-width-lg) {
      display: none !important;
    }
  }

  .StickyTray__list {
    @extend .ds-u-margin-left--0;
  }
}
