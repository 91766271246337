.Tooltip {
  @extend .ds-u-display--inline-block;

  &--inline {
    @extend .ds-u-display--inline;
  }

  &__header {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-margin-bottom--1;

    .ds-text-heading--lg {
      @extend .ds-u-margin-bottom--0;
    }

    .Tooltip__button--close {
      // IE11 fix
      min-width: 32px;
    }
  }

  a {
    color: $color-secondary;
  }

  &__trigger {
    color: $color-gray-dark !important;
    @extend .ds-u-padding--0;
    @extend .ds-u-margin-right--1;
    @extend .ds-u-text-align--left;
    padding-bottom: 1px !important;
    text-decoration: underline dashed $color-gray !important;
    text-underline-position: under;

    &.ds-c-tooltip__trigger-link {
      @extend .ds-u-margin-right--0;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      text-decoration: none !important;

      > span {
        border-bottom: 1px dashed $color-gray !important;
      }
    }

    &:hover {
      color: $color-gray-dark !important;
    }

    strong & {
      @extend .ds-u-font-weight--bold;
    }
  }
}
