.PlanPreferencePage,
.DrugSearchPreferencePage {
  @extend .ds-u-padding--0;
  @extend .ds-u-fill--background;

  & .ds-c-alert {
    @extend .ds-u-margin-y--2;
  }

  &__enterZip {
    @extend .ds-u-margin-top--6;
    & .ds-c-label {
      @extend .ds-u-margin-y--1;
    }
  }

  // @TODO - Look for a better way to achieve the desired layout styles, if possible
  // This is using specificity to override intrinsic element styles with other
  // element styles from the DS
  .CountyPicker__label,
  .StatePicker__label,
  .mct-l-h3 {
    @extend .ds-text-heading--xl;
  }

  .CountyPicker__submit {
    @media screen and (max-width: $media-width-md) {
      width: 100%;
    }
  }
}

.CoverageSelection {
  & .ds-c-alert {
    @extend .ds-u-margin-y--2;
  }
}

.ContinueSection,
.HasMedicareNumber,
.ViewMedigapPolicies {
  @extend .ds-u-margin-y--5;
  @extend .ds-u-border-top--2;
}

.ContinueSection {
  @extend .ds-u-padding-y--4;
}

.HasMedicareNumber {
  @extend .ds-u-border--0;

  span {
    display: inline !important;
  }
}
