.mct-c-modal-m3p {
  --table__padding: 12px;

  @media print {
    overflow: unset;
    max-width: unset;
    width: 100%;
    max-height: unset;
    margin: 0;
  }

  &__header {
    h1 {
      @extend .ds-u-margin-bottom--0;
    }
  }

  &__change-button-container {
    // @extend .ds-u-margin-top--1;
    // @extend .ds-u-md-margin-top--0;
    @extend .ds-u-md-margin-left--2;

    // @media screen and (min-width: $media-width-md) {
    //   padding-top: 80px;
    // }
  }

  &__change-button {
    @media screen and (max-width: $media-width-sm) {
      width: 100%;
    }
  }

  &__cost-breakdown {
    width: 100%;
  }

  &__select-table-container {
    gap: $spacer-2;
    width: 100%;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @media screen and (min-width: $media-width-sm) {
      &:not(&--oon) {
        width: fit-content;
      }
    }
  }

  &__cost-breakdown-stacked-header {
    @extend .ds-u-fill--gray-lightest;

    @media screen and (min-width: $media-width-sm) {
      background-color: $color-white !important;
    }
  }
}
