.EnrollTransitionDialog {
  @extend .ds-u-text-align--center;

  .ds-c-icon--lock {
    font-size: 11px;
  }

  &__header {
    @extend .ds-u-padding--0;
  }

  ul {
    @extend .ds-u-margin-top--1;
  }

  &__content {
    @extend .ds-u-font-size--sm;
    @extend .ds-u-md-font-size--base;
    @extend .ds-u-margin-bottom--2;

    ul {
      @extend .ds-u-color--gray;
    }
  }

  &__checklist {
    list-style-type: disc;
  }

  &__cancelButton {
    @extend .ds-u-lg-margin-right--3;
    @extend .ds-u-margin-bottom--2;
    @extend .ds-u-lg-margin-bottom--0;
  }

  &__planDetails {
    @extend .ds-u-padding-bottom--2;
    @extend .ds-u-border-bottom--2;
  }

  &__actions {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-lg-display--block;
  }

  &__medigap {
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -32px;
    @extend .ds-u-margin-top--2;
    @extend .ds-u-padding-x--4;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-fill--gray-lightest;
  }
}
