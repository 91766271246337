.AtoZDrugList {
  &__resultsContainer {
    flex-grow: 1;
  }

  &__selectContainer {
    @extend .ds-u-fill--gray-lightest;
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-padding-y--1;
    position: sticky;
    left: 0;
    top: 0;
    transition: 0.2s ease;

    &--shadowed {
      box-shadow: 0 4px 6px 0 rgba($color-gray, 0.5);
    }
  }

  &__selectLabel {
    @extend .ds-u-margin-right--2;
  }

  &__total {
    border-bottom: 2px solid $color-gray-lighter;
    @extend .ds-u-padding-y--1;
    @extend .ds-u-margin-x--2;
  }

  &__results {
    @extend .ds-c-list--bare;
    padding-bottom: 67px;

    li {
      @extend .ds-u-focus-within;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      label {
        @extend .ds-u-padding-x--2;
        @extend .ds-u-padding-y--1;
        @extend .ds-u-margin-top--0;
        cursor: pointer;
        border: none;
        width: 100%;
        text-align: left;
        display: block;
        background: none;
        font-size: $font-size-md;

        &.selected {
          @extend .ds-u-fill--secondary;
          @extend .ds-u-color--white;
        }
      }
    }
  }

  &__drawerBody {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    min-height: 100%;
  }

  &__confirm {
    &.js-is-sticky {
      width: 542px;
      left: auto;
      bottom: 16px !important;
    }

    &.js-is-stuck {
      position: relative !important;
    }

    @extend .ds-u-fill--secondary;
    @extend .ds-u-padding--2;
    @extend .ds-u-color--white;
    position: sticky;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    box-shadow: 0 -3px 9px -1px $color-gray;
    margin: -16px;
  }

  &__confirmActions {
    @extend .ds-u-display--flex;
  }

  &__selectLabel {
    @extend .ds-u-margin-top--0;
    @extend .ds-u-font-weight--normal;
    @extend .ds-u-margin-right--2;
  }
}
