.mct-p-plan-details {
  .Footnote {
    &__marker {
      // helps scrollingIntoView work properly with StickyNav height
      scroll-margin-top: 100px;
    }
  }
}

.PlanDetailsSection {
  @extend .ds-u-margin-bottom--7;

  .Collapsible {
    break-inside: avoid;
  }

  &:focus-visible {
    outline: none;
  }

  &__alert-container {
    @extend %mct-c-table__container-base;
    @extend %mct-c-table__caption-base;
    border-inline-width: 0 !important;
    @media screen and (min-width: $media-width-lg) {
      border-inline-width: 1px !important;
    }
    border-style: solid;
  }

  &__heading {
    @extend .ds-u-fill--gray-lightest;
    @extend .ds-u-padding--2;
    @extend .ds-u-margin--0;
    @extend .ds-u-border--1;
    @extend %mct-l-sm-flush-to-edge;

    h2 {
      @extend .ds-u-font-size--xl;
      @extend .ds-u-md-font-size--h2;
    }

    @media print {
      background-color: $color-white !important;
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &__collapse-container {
    display: block;
    &--collapsed {
      height: 0 !important;
      overflow: hidden;
      visibility: hidden;

      @media print {
        height: auto !important;
        visibility: visible;
      }
    }
    &--expanded {
      height: auto;
    }
  }

  &__collapse-trigger {
    .mct-c-icon--plus-minus {
      font-size: 11px;
    }
  }
}

.OptionalPackagesTable {
  td {
    vertical-align: text-top;
  }

  tbody tr td:first-child {
    width: 70%;
  }
}

.two-column-table {
  .mct-c-table__header,
  .mct-c-table__cell {
    vertical-align: text-top;

    @media screen and (min-width: $media-width-lg) {
      width: 50% !important;
    }

    @media print {
      width: 50% !important;
    }
  }
}

.three-column-table {
  .mct-c-table__header,
  .mct-c-table__cell {
    vertical-align: text-top;

    @media screen and (min-width: $media-width-lg) {
      width: 33% !important;
    }

    @media print {
      width: 33% !important;
    }
  }
}

.pharmacy-column-table {
  &__1 {
    .mct-c-table__column--0 {
      width: 50%;
    }
  }
  &__2 {
    .mct-c-table__column--0 {
      width: 40%;
    }
  }
  &__3 {
    .mct-c-table__column--0 {
      width: 30%;
    }
  }
  &__4,
  &__5 {
    .mct-c-table__column--0 {
      width: 20%;
    }
  }
}
