.appPage {
  @extend .ds-u-padding--0;
  @extend .ds-u-margin--0;
}

.appPage__banner {
  @extend .ds-u-padding-x--2;
  @extend .ds-u-sm-padding-x--7;
  padding-top: 24px;
  padding-bottom: 32px;
  position: relative;

  @media screen and (min-width: $media-width-md) {
    padding-top: 56px;
  }
}

.appPage__header-extension {
  @extend .ds-u-padding-bottom--0;
  @extend .ds-u-padding-top--1;
  background-color: $color-white;

  @media screen and (min-width: $media-width-md) {
    padding-top: 24px !important;
  }
}

.appPage__content {
  @extend .ds-u-margin-x--2;
  @extend .ds-u-sm-margin-x--7;
  @extend .ds-u-padding-bottom--3;
  background-color: $color-white;
}
