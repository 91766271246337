.mct-u-position--absolute {
  position: absolute;
}

.mct-u-position--relative {
  position: relative;
}

@each $breakpoint in map-keys($breakpoints) {
  $positions: static, relative, absolute, sticky;
  $value: map-get($breakpoints, $breakpoint);
  @each $pos in $positions {
    .mct-u-#{$breakpoint}-position--#{$pos} {
      @media (min-width: $value) {
        position: #{$pos} !important;
      }
    }
  }
}

@each $scale, $size in $spacers {
  $directions: top, right, bottom, left;
  @each $dir in $directions {
    .mct-u-#{$dir}--#{$scale} {
      #{$dir}: #{$size} !important;
    }
  }
}
