.mct-c-header-group {
  @extend .ds-u-padding-bottom--2;
  @media screen and (min-width: $media-width-lg) {
    padding-top: $spacer-3;
    padding-bottom: $spacer-3;
  }
  &--ntm {
    @extend .ds-u-padding-top--1;
    @media screen and (min-width: $media-width-lg) {
      padding-top: $spacer-4;
      padding-bottom: $spacer-3;
    }
  }
}
