.EnrolledProcessedBy {
  @extend .ds-u-display--flex;
  @extend .ds-u-align-items--start;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-margin-top--7;

  &__beneficiaryName {
    text-transform: capitalize;
  }
}

.Enrolled {
  &__list {
    @extend .ds-u-display--block;
    li {
      @extend .ds-u-margin-bottom--2;
    }
  }
}

.EnrolledContact {
  @extend .ds-u-display--flex;
  @extend .ds-u-align-items--start;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-margin-top--3;

  &__heading {
    @extend .ds-u-font-weight--bold;
    font-family: $font-family-montserrat;
    font-size: 21px;
  }

  &__printButton {
    @extend .ds-u-margin-top--2;
    @extend .ds-u-padding-x--4;
  }

  address {
    @extend .ds-u-margin-bottom--2;
  }
}

.EnrolledDetails {
  li {
    @extend .ds-u-margin-y--2;
  }
}
