.PlanDetailsPagePlanInfo {
  @extend .ds-u-display--flex;
  @extend .ds-u-margin-y--1;
  @extend .ds-u-justify-content--between;

  &__planName {
    display: flex;
    flex-direction: column-reverse;
  }

  &__leftCol {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
  }

  &__rightCol {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--end;
    @extend .ds-u-align-items--center;
  }

  &__label {
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-color--gray-light;
    font-size: $font-size-lg;
  }

  &__value {
    @extend .ds-u-color--gray-light;
    font-size: $font-size-lg;
  }

  &__printButton {
    @extend .ds-u-display--none;
    @extend .ds-u-sm-display--block;
  }
}
