.PapLandingPage {
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-align-items--center;
  margin-bottom: -48px;
  background-color: #f3f3f3;
  box-shadow: 0 50vh 0 50vh #f3f3f3;

  &__hero {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-justify-content--start;
    background: none;
    background-color: #41716a;
    background: url(../../../assets/PAP_landing.jpg);
    background-clip: border-box;
    background-position: 100% 0%;
    background-size: cover;
    width: 100%;
    padding-bottom: 100px !important;

    @media screen and (max-width: $media-width-sm) {
      background-position: 80% 0%;
    }

    @media screen and (max-width: $media-width-md) {
      padding-bottom: 64px !important;
    }
  }

  &__heroContent {
    @extend .ds-base--inverse;
    @extend .ds-u-fill--transparent;
    @extend .ds-u-padding-top--7;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--center;

    h1 {
      max-width: 40ex;
    }

    @media screen and (max-width: $media-width-md) {
      padding: 24px;
    }
  }

  &__heroHeader {
    @extend .ds-u-margin-y--0;
    @extend .ds-u-margin-x--2;
    @extend .ds-text-heading--3xl;
    text-align: center;

    @media screen and (min-width: $media-width-md) {
      margin-top: 48px;
      margin-bottom: 8px !important;
    }
  }

  &__heroSubHeader {
    font-weight: 400;
    font-size: $font-size-lg;
    line-height: 28px;
  }

  &__content {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--center;
    margin-top: -64px;
    margin-bottom: 96px;
  }

  &__card {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-padding-bottom--3;
    width: 100%;

    .ContinueSection {
      @extend .ds-u-margin-bottom--0;
      @extend .ds-u-padding-bottom--0;
      @extend .ds-u-margin-top--1;
      @extend .ds-u-padding-top--2;
    }

    @media screen and (min-width: $media-width-md) {
      max-width: 678px;
      padding-left: 90px;
      padding-right: 90px;
      padding-bottom: 80px;

      & input {
        margin-right: 8px;
      }
    }

    &:first-child {
      @extend .m-c-card;
      @extend .ds-u-margin-bottom--4;
    }
  }

  &__statePicker {
    & input {
      width: 100%;
    }

    & button {
      width: 180px;
    }

    @media screen and (max-width: $media-width-sm) {
      width: 100%;
      .StatePicker__input {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap !important;
      }

      .CountyPicker__input {
        margin: 0 !important;
        width: 100%;
      }

      & input {
        width: 100%;
      }

      & button {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
}
