.CoverageWizardOptionsPage {
  &__omDesktopButton {
    @media screen and (min-width: 768px) and (max-width: 882px) {
      height: 88px;
    }
  }

  &__container {
    @media screen and (min-width: $media-width-md) {
      width: calc(#{$media-width-md} - 50px);
    }

    @media screen and (min-width: $media-width-lg) {
      width: calc(#{$media-width-lg} - 50px);
    }
  }

  &__banner {
    @extend .ds-u-padding-bottom--0;
    @extend .ds-u-padding-top--2;
  }

  &__cards {
    @extend .ds-c-list--bare;
    @extend .ds-u-md-display--flex;
    @extend .ds-u-md-flex-direction--row;
    @extend .ds-u-margin-top--5;
    gap: 0px;

    & > li {
      @media screen and (min-width: $media-width-md) {
        width: 49%;
        max-width: 430px;
      }

      &:first-child {
        @extend .ds-u-md-margin-right--3;
      }
    }
  }

  &__card {
    @extend .ds-u-padding--3;
    box-sizing: border-box;
    @extend .ds-u-margin-bottom--2;
    @extend .ds-u-md-margin-bottom--0;

    h2 {
      @media screen and (min-width: 768px) and (max-width: 882px) {
        height: 64px;
      }
    }

    ul {
      padding-inline-start: 24px;

      @media screen and (min-width: $media-width-md) {
        height: 244px;
        min-height: 244px;
      }
    }
  }
}
