.CountySearchField {
  @extend .ds-u-padding-top--3;
  @extend .ds-u-padding-bottom--5;

  & input {
    height: 56px;
    width: 300px;
  }

  & > label > .ds-c-field__hint.ds-u-color--error {
    text-transform: none !important;
  }
}
