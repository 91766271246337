.MedigapBenefits__HelpDrawer {
  .ds-c-help-drawer {
    @media screen and (min-width: $media-width-md) {
      max-width: 60%;
    }
  }

  .Tooltip__header {
    @extend .ds-u-text-transform--capitalize;
  }

  .MedigapTable__tableLegends {
    display: flex;
    justify-content: space-around;
    /* fallback, ie does not handle space-evenly properly */
    justify-content: space-evenly;
    flex-direction: column;

    @media screen and (min-width: $media-width-lg) {
      flex-direction: row;
    }
  }

  .MedigapTable__Table {
    @extend .ds-c-table;
    @extend .ds-c-table--borderless;
    font-size: 14px;

    thead {
      @extend .ds-u-font-size--lg;
      font-family: $font-family-montserrat;

      th {
        @extend .ds-u-font-weight--semibold;
        @extend .ds-u-padding-y--1;
        background-color: $color-white;
      }

      tr:first-child {
        th {
          @extend .ds-u-border-bottom--0;
          @extend .ds-u-padding-bottom--2;
        }
      }
    }

    th,
    td {
      border-color: #ddd;
      text-align: center;
    }

    tr th:nth-of-type(odd),
    tr td:nth-of-type(odd) {
      background-color: $color-gray-lightest;
    }

    tr th:first-of-type,
    tr td:first-of-type {
      background-color: #e0f2f1;
      text-align: left;
    }

    .MedigapTable__BottomRow td {
      border: none;
    }

    .Tooltip {
      &__trigger {
        font-size: 14px;
      }

      &__header > h2::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .MedigapTable__TableCheckmark {
    width: 14px;
  }
}
