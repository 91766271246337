h1,
.ds-text-heading--3xl {
  margin-bottom: $spacer-2;
}

.ds-u-font-family--montserrat {
  font-family: $font-family-montserrat;
}

.ds-u-font-family--rubik {
  font-family: $font-family-rubik;
}
