.mct-c-dl {
  &__def {
    @extend .ds-u-margin-left--0;
  }
  &__term {
    @extend .ds-u-font-weight--bold;
    &::after {
      content: ": ";
    }
  }

  &--no-colon {
    .mct-c-dl__term {
      &::after {
        content: "";
      }
    }
  }
  &--inline,
  &--inline-block {
    .mct-c-dl__def,
    .mct-c-dl__term {
      @extend .ds-u-display--inline;
    }
  }
  &--inline-block {
    .mct-c-dl__def {
      &::after {
        content: "\00000a";
        white-space: pre;
      }
    }
  }
}
