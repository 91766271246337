.Pagination {
  @extend .ds-u-display--flex;
  @extend .ds-u-border-top--1;
  @extend .ds-u-border-bottom--1;
  @extend .ds-u-justify-content--between;
  @extend .ds-u-md-justify-content--center;
  width: 100%;

  &__results {
    @extend .ds-c-list--bare;
    @extend .ds-u-display--none;
    @extend .ds-u-md-display--flex;
    @extend .ds-u-md-flex-direction--row;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-align-items--center;
    @extend .ds-u-padding-y--2;
    gap: 0;

    li {
      @extend .ds-u-margin-bottom--0;
    }
  }

  &__pageButton {
    @extend .ds-u-text-decoration--none;
    @extend .ds-u-padding--0;
    margin: 0 12px;
    border-radius: 0 !important;
    @extend .ds-u-border--0;
    border-bottom: 2px solid transparent !important;

    &--active {
      @extend .ds-u-font-weight--semibold;
      border-bottom: 2px solid $color-gray-dark !important;
    }
  }

  &__navButton {
    @extend .ds-u-padding-y--0;
    @extend .ds-u-text-decoration--none;
    white-space: nowrap;
  }

  &__buttonContainer {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-padding-y--2;

    @media screen and (max-width: $media-width-md) {
      min-width: 25%;
    }
  }

  &__mobileResults {
    @extend .ds-u-md-display--none;
    @extend .ds-u-border-left--1;
    @extend .ds-u-border-right--1;
    @extend .ds-u-padding-x--3;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-align-items--center;
    white-space: nowrap;
  }
}
