.mct-c-login-form {
  &__login-button {
    width: 100%;
    @media screen and (min-width: $media-width-lg) {
      width: auto;
    }
  }
  &__heading {
    @extend .ds-text-heading--lg;
    @extend .ds-u-margin-bottom--2;
    @media screen and (min-width: $media-width-lg) {
      font-size: $font-size-xl;
    }
  }
}
