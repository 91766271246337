.EnrollmentFormPage {
  &__Sep2021 {
    max-width: 720px;
    &-header--confirm-enrollment-period {
      @media screen and (max-width: $media-width-sm) {
        // mobile fix to keep heading on one line for Iphone XR users
        font-size: 22px;
      }
    }
  }

  &__agreementList {
    li:not(:last-child) {
      @extend .ds-u-margin-bottom--1;
    }
  }

  &__sepTable {
    border-collapse: collapse;
    width: 100%;
    min-width: 800px;

    td,
    th {
      width: 33%;
      @extend .ds-u-padding--1;
      @extend .ds-u-text-align--left;
      @extend .ds-u-valign--top;

      &:not(:last-child) {
        @extend .ds-u-border-right--1;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: $color-gray-lightest;
        }
      }
    }

    thead,
    tfoot {
      background-color: $color-secondary-lightest;
    }

    tfoot {
      td {
        @extend .ds-u-font-weight--normal;

        sup {
          margin-right: 4px;
        }
      }
    }
  }

  .m-c-navbar {
    border-bottom: 4px solid $color-primary;
  }

  &__steps {
    @extend .ds-u-display--block;
    @extend .ds-c-list--bare;
    @extend .ds-u-flex-direction--row;
  }

  &__step {
    @extend .ds-u-display--inline-block;
    background-color: $color-gray-lighter;
    width: 24px;
    height: 4px;

    &--active {
      background-color: $color-secondary-light !important;
    }

    &:not(:last-child) {
      @extend .ds-u-margin-right--1;
    }
  }

  &__content {
    @extend .ds-u-sm-padding-top--3;
    @extend .ds-u-padding-top--1;
    @extend .appPage__content;
    @extend .ds-u-margin-x--2;
    @extend .ds-u-sm-margin-x--7;
  }

  &__stepsText {
    @extend .ds-u-margin-top--0;
    @extend .ds-u-color--secondary-light;
    font-size: 18px;
  }

  &__buttons {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-md-flex-direction--row;

    @extend .ds-u-margin-top--5;
    margin-bottom: 64px;
  }

  &__header {
    @extend .ds-text-heading--3xl;
    @extend .ds-u-font-size--4xl;
    @extend .ds-u-margin-top--2;
  }

  &__nextButton {
    @media screen and (min-width: $media-width-md) {
      min-width: 150px;
    }
  }

  &__expandButton {
    @extend .ds-u-color--secondary-light;
    @extend .ds-u-margin-bottom--1;

    &:focus,
    &:active {
      color: $color-secondary-light !important;
    }
  }

  &__expandCaret {
    @extend .ds-u-color--secondary-light;

    * {
      fill: $color-secondary-light;
    }
  }

  &__reviewBox {
    @extend .ds-u-border--2;
    @extend .ds-u-margin-top--3;
    max-width: 800px;

    ul {
      @extend .ds-c-list--bare;
      @extend .ds-u-padding-y--0;
      @extend .ds-u-padding-x--3;

      li {
        @extend .ds-u-padding-y--2;

        p:first-child {
          font-weight: 500;
        }

        p {
          @extend .ds-u-margin--0;
        }

        &:not(:last-child) {
          @extend .ds-u-border-bottom--2;
        }
      }
    }
  }

  &__backButton {
    @extend .ds-u-md-margin-right--3;
    @extend .ds-u-margin-bottom--3;
    @extend .ds-u-md-margin-bottom--0;

    @media screen and (min-width: $media-width-md) {
      width: 150px;
    }
  }

  &__checkbox > label {
    @extend .ds-u-font-weight--semibold;
  }

  section:not(:first-of-type) {
    @extend .ds-u-margin-top--4;
    @extend .ds-u-padding-top--4;
    @extend .ds-u-border-top--1;
  }

  section:not(:last-of-type) {
    @extend .ds-u-margin-bottom--4;
  }

  fieldset {
    @extend .ds-c-fieldset;
    margin: 0;
    legend:first-child {
      padding-inline-start: 0;
    }
  }
}
