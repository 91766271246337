@import "./styles/index";

body {
  margin: 0;
}

@page {
  size: "A3";
  margin: 0mm;
}

.mct-print-only {
  display: none;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  .mct-hide-print,
  .m-c-footer,
  .mct-c-navbar {
    display: none !important;
  }

  .ds-l-row,
  .mct-print-only {
    display: block !important;
  }

  .mct-print-break-inside--avoid {
    break-inside: avoid;
  }

  .mct-print-break-before--page {
    break-before: page;
  }
}
