.mct-c-summary-page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer-4;
  margin-top: $spacer-6;
  @media screen and (max-width: $media-width-sm) {
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-bottom: $spacer-3;
    margin-top: $spacer-2;
  }
  &__alert {
    min-width: 100%;
  }
  &__alert,
  &__info {
    @extend .ds-u-measure--base;
  }
  &__content {
    margin-inline-end: 0;
    @media screen and (min-width: $media-width-sm) {
      margin-inline-end: $spacer-2;
    }
  }
  &__greeting {
    @extend .ds-text-heading--3xl;
  }
  &__info {
    @extend .ds-text-body--lg;
  }
  &__print {
    @media screen and (max-width: $media-width-sm) {
      margin-bottom: $spacer-2;
    }
  }
}
