/**
 * Takes a background color and optional second boolean parameter, which when
 * `true` sets content color to white
 * Default content color is dark gray
 * Manually test for color-contrast A11Y
 */
@mixin theme-badge($background-color, $white-content: false) {
  $color: $color-gray-darker;
  @if ($white-content == true) {
    $color: $color-white;
  }
  // increase specificity for theme over variation
  &.ds-c-badge {
    color: $color;
    background-color: $background-color;
  }
}

.mct-c-badge {
  @extend .ds-u-radius--pill;
  @extend .ds-u-padding-y--1;
  @extend .ds-u-padding-x--2;
  display: inline-flex;
  align-items: center;

  &__icon {
    height: 18px;
    width: 18px;
    &--left {
      @extend .ds-u-margin-right--05;
    }
    &--right {
      @extend .ds-u-margin-left--05;
    }
  }

  &__content {
    padding: 2px;
    font-size: $font-size-md;
  }

  /**
   * Themes
   * 
   * Until the day that there's a simple and lightweight way to set color-contrast
   * for A11Y, ensure that content color provides sufficient contrast against the
   * chosen background. See mixin `@theme-badge` usage.
   */

  &--primary-alt-lightest {
    @include theme-badge($color-secondary-lightest);
  }

  &--white {
    @include theme-badge($color-white);
  }
}

.ds-c-badge {
  &.primary-alt {
    background-color: $color-secondary;
    &--inverted {
      background-color: transparent;
      color: $color-secondary;
      border: 1px solid $color-secondary;
    }
  }
}
