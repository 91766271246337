#mpf-root {
  @media print {
    main header {
      .m-c-globalHeader__actions button {
        display: none;
      }
      .m-c-globalHeader__logo {
        padding: 8px !important;
      }
    }

    .appPage__content {
      margin-left: 1em !important;
      margin-right: 1em !important;
    }
  }
}

.MedigapPlans {
  h1 {
    @extend .ds-u-margin-top--1;
  }

  &__sortSelectLabel {
    white-space: nowrap;
    @extend .ds-u-margin--0;
    @extend .ds-u-margin-right--2;
  }
}

.mct-c-accurate-price-container {
  @extend .ds-u-fill--gray-lightest;
  @extend .ds-u-padding--0;
  @extend .ds-u-padding-y--3;
  @extend .ds-u-sm-padding--3;
  @extend .ds-u-md-padding--5;
  @extend .ds-l-row;
  margin-left: -56px;
  margin-right: -56px;
  padding-top: 24px !important;

  @media screen and (max-width: $media-width-sm) {
    margin-left: -16px;
    margin-right: -16px;
  }

  &__heading {
    @extend .ds-l-col--12;
    @extend .ds-u-margin-bottom--4;
    @extend .ds-u-sm-margin-bottom--3;
  }

  &__body {
    @extend .ds-l-col--12;
    @extend .ds-l-lg-col--9;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-wrap--wrap;
    @extend .ds-u-md-flex-wrap--nowrap;
    @extend .ds-u-justify-content--between;

    & > *:not(:last-child) {
      @extend .ds-u-padding-right--0;
      @extend .ds-u-md-padding-right--3;
      @extend .ds-u-margin-bottom--3;
      @extend .ds-u-md-margin-bottom--0;
    }
  }
  &__age-container {
    @extend .ds-l-col--12;
    @extend .ds-l-sm-col--4;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
    @extend .ds-u-margin-bottom--1;
    @extend .ds-u-md-margin-bottom--0;
  }
  &__non-age-container {
    @extend .ds-l-col--12;
    @extend .ds-l-sm-col--8;
    @extend .ds-u-display--flex;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
  }

  &__health {
    @extend .ds-l-col--12;
    @extend .ds-l-md-col--8;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
  }
  &__health--no-hh-flag {
    @extend .ds-l-col--12;
    @extend .ds-l-md-col--12;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
  }
  &__age-metric {
    @extend .ds-l-col--12;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
  }
  &__sex-metric {
    @extend .ds-l-col--6;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
  }
  &__tobacco-metric {
    @extend .ds-l-col--6;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
  }
  &__household {
    @extend .ds-l-col--12;
    @extend .ds-l-md-col--4;
    @extend .ds-u-padding-left--0;
    @extend .ds-u-padding-right--0;
    .ds-c-help-drawer__toggle {
      text-align: left;
      .icon-wrapper {
        /** this prevents a visual bug where the hover shadow will show up on a higher line
        if the icon is pushed to the next line. */
        display: inline-block;
      }
    }
  }

  &__action {
    @extend .ds-l-col--12;
    @extend .ds-l-lg-col--3;
    @extend .ds-u-margin-top--4;
    @extend .ds-u-lg-margin-top--0;
    align-self: center;
    white-space: normal;

    button {
      width: 100% !important;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &__inline_label {
    @extend .ds-u-margin-top--0;

    & > *:not(legend) {
      @extend .ds-u-margin-right--2;
      @extend .ds-u-md-margin-right--0;
      @extend .ds-u-md-display--block;
    }
  }
}
