// @TODO - This might be even better as a mixin with various width parameters
%mct-c-summary-page-card--medium {
  padding: $spacer-3;
  width: $summary-page-card-medium--width-lg;
  margin-right: $spacer-3;

  @media screen and (max-width: $media-width-md) {
    width: $summary-page-card-medium--width-md;
  }
  @media screen and (max-width: $media-width-sm) {
    width: auto;
    margin: 0 0 $spacer-3;
  }
  @media print {
    border: none;
    box-shadow: none;
    display: block;
    margin: 0 $spacer-3 0;
    min-height: auto;
    padding: 0 !important;
    width: 490px;
  }
}
