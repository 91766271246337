%message-content-base {
  max-width: 100ex;
  padding-right: $spacer-1;
}

%message-title-base {
  @extend .ds-text-heading--md;
  margin-bottom: $spacer-half;
  margin-top: 0;
}

%content-no-title-first-child {
  > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }
}

%mct-c-alert-message-base-decoration {
  border-radius: $spacer-1;
  box-shadow: 0 2px 4px 0 rgba($color-black, 0.5);
}

%mct-c-alert-message-base-dimensions {
  // based off of design documents
  max-width: 1077px;
  min-width: fit-content;
}

.mct-c-alert-message {
  $root: &; // Reference to the root class name
  @extend %mct-c-alert-message-base-decoration;
  @extend %mct-c-alert-message-base-dimensions;
  border-top: solid $spacer-1 $color-secondary;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-areas:
    "title close"
    "body body"
    "action action";
  padding: $spacer-2;
  @media screen and (min-width: $media-width-sm) {
    &:not(&--narrow) {
      padding: $spacer-4;
      // to offset top border
      padding-top: $spacer-3;
      grid-template-columns: min-content 1fr min-content;
      grid-template-areas:
        "icon content close"
        ". content ."
        ". action .";
    }
  }
  &__icon {
    display: none;
    grid-area: icon;
    @media screen and (min-width: $media-width-sm) {
      display: block;
      margin-right: $spacer-3;
      svg {
        font-size: $font-size-xl;
        color: $color-secondary;
      }
      circle {
        fill: $color-secondary;
      }
    }
  }
  &__body {
    @extend .ds-u-sm-display--none;
    grid-area: body;
    @extend %message-content-base;
    &--no-title {
      @extend %content-no-title-first-child;
    }
  }
  &__title {
    @extend .ds-u-sm-display--none;
    grid-area: title;
    @extend %message-content-base;
    @extend %message-title-base;
  }
  &__action {
    display: flex;
    grid-area: action;
    justify-content: center;
    margin-top: $spacer-3;
    @media screen and (min-width: $media-width-sm) {
      display: block;
    }
    & > * {
      width: 100%;
      @media screen and (min-width: $media-width-sm) {
        width: auto;
      }
    }
  }
  &__close-button {
    display: block;
    grid-area: close;
    margin-left: auto;
    .ds-c-button {
      border: 0;
      padding-right: 0;
    }
  }
  &__combined {
    &-content {
      @extend .ds-u-display--none;
      @extend .ds-u-sm-display--block;
      grid-area: content;
      @extend %message-content-base;
      #{$root}--narrow & {
        @extend .ds-u-sm-display--none;
      }
    }
    &-title {
      @extend %message-title-base;
    }
    &-body {
      &--no-title {
        @extend %content-no-title-first-child;
      }
    }
  }
  &--hidden {
    display: none;
  }

  /* In lieu of support of container queries */
  &--narrow {
    // from Sketch
    max-width: 273px;
    #{$root} {
      &__icon,
      &__combined {
        @extend .ds-u-display--none;
      }
      &__title,
      &__body {
        @extend .ds-u-display--block;
      }
      &__action {
        display: flex;
        button {
          width: 100%;
        }
      }
    }
  }
  /* CTA variants */
  $cta-grid-row-gap: $spacer-3;
  $cta-grid-column-gap: $spacer-2;
  &#{$root}--variant {
    background-color: $color-primary-lightest;
    border-top-width: 0;
    gap: $cta-grid-row-gap $cta-grid-column-gap;
    grid-template-columns: auto;
    padding-inline: $spacer-3;
    #{$root} {
      &__content,
      &__combined-content {
        padding-inline: 0;
        font-size: $font-size-lg;
      }
      &__icon {
        display: none;
      }
      &__close-button {
        display: none;
      }
      &__action {
        margin-top: 0;
        align-self: flex-start;
        justify-self: flex-start;
      }
      &__title,
      &__combined-title {
        color: $color-secondary;
        font-size: $font-size-lg;
        margin-bottom: $spacer-1;
      }
    }
    &:not(&-narrow) {
      #{$root} {
        &__action {
          text-wrap: wrap;
          @media screen and (min-width: $media-width-sm) {
            text-wrap: nowrap;
          }
          @media screen and (min-width: $media-width-md) {
            align-self: center;
          }
        }
        &__title,
        &__combined-title {
          @media screen and (min-width: $media-width-sm) {
            font-size: $font-size-xl;
          }
        }
      }
    }
  }
  &#{$root}--variant-cta {
    grid-template-areas:
      "body"
      "action";
    &#{$root}--with-title {
      grid-template-areas:
        "title"
        "body"
        "action";
      #{$root}__body {
        margin-top: -#{$cta-grid-row-gap}; /* Override gap with negative margin in this context */
      }
    }
    &:not(&-narrow) {
      @media screen and (min-width: $media-width-sm) {
        grid-template-areas:
          "content"
          "action";
      }
      @media screen and (min-width: $media-width-md) {
        grid-template-areas: "content action";
        padding-top: $spacer-4;
        place-items: center;
      }
    }
  }
  /* Wrapper around CTA + Image variant */
  $cta-image-top: 20px;
  &-container {
    margin-top: $cta-image-top;
    max-width: fit-content;
    position: relative;
  }

  /** 
   * A flex, sub-container for the CTA + Image variant, to deal with that variant's
   * specific layout needs
   * Only rendered for the CTA + Image variant
   */
  &__content-action-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $spacer-2;
  }
  @media screen and (min-width: $media-width-md) {
    &:not(&--narrow) {
      #{$root}__content-action-container {
        flex-direction: row;
        gap: $spacer-1;
        padding-block: $spacer-3;
        place-items: center;
        #{$root}__body,
        #{$root}__combined-content {
          flex-basis: 70%;
          flex-grow: 1;
        }
      }
    }
  }
}
