.PlanFilters {
  @media print {
    display: none;
  }

  &__header {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-wrap--wrap;
    @extend .ds-u-justify-content--between;
    align-items: center;

    @media screen and (max-width: $media-width-md) {
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }

  &__headerActions {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-wrap--wrap;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-padding-bottom--0;
    @extend .ds-u-margin-bottom--3;

    .ChangeLocation {
      width: 100%;
    }

    .ChangeLocation__buttonGroup {
      @extend .ds-u-display--flex;
      @extend .ds-u-justify-content--between;
      @extend .ds-u-align-items--center;
      width: 100%;
    }

    .CCXPBase__button {
      @extend .ds-u-padding-y--2;
      @extend .ds-u-xl-justify-content--between;

      &.width-bug {
        @media screen and (max-width: $media-width-lg) {
          width: 90%;
        }
      }
    }
  }

  &__action {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--start;
    @extend .ds-u-lg-margin-right--3;

    @media screen and (max-width: $media-width-lg) {
      width: 100%;

      .CCXPBase {
        width: 100%;
      }
    }

    &__label {
      @extend .CCXPBase__label;
      @extend .ds-u-padding-top--1;
      @extend .ds-u-padding-bottom--0;
    }

    &__description {
      @extend .ds-u-font-weight--bold;
      @extend .ds-u-font-family--montserrat;
      @extend .ds-u-font-size--base;
      @extend .ds-u-margin-top--0;
      @extend .ds-u-margin-bottom--1;
      color: $color-gray-dark;

      // This hacky bit of code is added to overwrite a high specificity "!important" rule.
      .mct-c-help-drawer &:last-child {
        margin-bottom: $spacer-1 !important;
      }
    }
  }

  &__noPlansWarning {
    @extend .ds-u-margin-y--2;
  }

  &__mobileActions {
    &.js-is-sticky {
      width: 542px;
      left: auto;
      bottom: 16px !important;
    }

    &.js-is-stuck {
      position: relative !important;
    }

    @extend .ds-u-fill--white;
    @extend .ds-u-padding--2;
    position: sticky;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    box-shadow: 0 -3px 9px -1px $color-gray;
    margin: -16px;
  }
}

.FilterBar {
  @extend %mct-l-flush-to-edge;
  border-top: 1px solid $color-gray-light;
  border-bottom: 1px solid $color-gray-lighter;
  background-color: $color-gray-lightest;

  &__heading {
    @extend .ds-u-margin-right--1;
    @extend .ds-u-font-weight--normal;
    @extend .mct-u-xs-visibility--screen-reader;
    @extend .ds-u-serif;
    @extend .ds-u-font-size--lg;
    white-space: nowrap;
    // legacy design decision pre medicare ds 7.0.0
    margin-bottom: 12px;
    line-height: 28px;
  }

  &__container-new-design {
    @media screen and (max-width: $media-width-sm) {
      width: 100%;
    }
  }

  &__toggleContainer {
    @extend .ds-u-margin-bottom--0;
    @extend .ds-u-md-margin-bottom--2;

    @media only screen and (min-width: 150px) and (max-width: 600px) {
      .ds-c-help-drawer {
        overflow-y: scroll;
      }
      .ds-c-help-drawer__body {
        overflow-y: visible;
      }
    }

    @media screen and (max-width: $media-width-sm) {
      width: 100%;
    }
  }

  &__toggle {
    @extend .ds-u-padding--1;
    @extend .ds-u-padding-left--2;
    @extend .ds-u-padding-right--2;
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-justify-content--center;
    @extend .ds-c-button;

    // ghost overrides
    background-color: $color-focus-light !important;
    border-width: 1px;
    border-color: currentColor !important;
    color: $color-secondary !important;
    text-decoration: none;
    line-height: 1.5;
    transform: translateY(2px);

    @media screen and (max-width: $media-width-sm) {
      width: 100%;
      background-color: $color-white;
      border: 1px solid $color-secondary !important;
      padding: 16px;
      text-decoration: none;
    }

    & > * {
      pointer-events: none;
    }
  }

  &__toggle-new-design {
    @extend .ds-u-margin-top--1;
    @extend .ds-u-margin-bottom--2;

    @media screen and (max-width: $media-width-sm) {
      width: 100%;
    }
    & > * {
      pointer-events: none;
    }
  }

  &__toggle-new-design-button {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-padding-y--2;

    @media screen and (max-width: $media-width-sm) {
      width: 100%;
    }
  }

  &__filters {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--baseline;
    @extend .ds-u-flex-wrap--wrap;

    &:only-child {
      @extend .ds-u-margin-bottom--2;
      @extend .ds-u-md-margin-bottom--0;
    }
  }

  .Filter {
    @extend .ds-u-padding-right--2;
    @extend .ds-u-margin-bottom--2;

    @media screen and (max-width: $media-width-xl) {
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }
    }

    @media screen and (max-width: $media-width-md) {
      &:nth-child(3),
      &:nth-child(4) {
        display: none;
      }
    }

    @media screen and (max-width: $media-width-sm) {
      display: none;
    }
  }

  .ds-c-help-drawer .ds-c-help-drawer {
    @media screen and (min-width: $media-width-md) {
      max-width: 100%;
    }
  }
}

.mct-c-filter-chips {
  @extend .ds-u-margin-top--2;
  @extend .ds-u-md-margin-top--0;
  @extend .ds-c-list;
  @extend .ds-c-list--bare;
  /** ds-c-list uses flex */
  @extend .ds-u-flex-direction--row;
  gap: 0;

  &__item {
    @extend .ds-u-padding-right--2;
    @extend .ds-u-padding-bottom--2;
  }

  // All non-standard margins/spacing per design specs
  &__button {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-justify-content--between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: $spacer-1;
    text-align: left;

    &-icon {
      height: 10px !important ;
      margin: 0 0 0 0 !important;
      min-width: 10px !important;
      position: static !important;
    }
  }
}
