.mct-c-alert {
  $alert: &;
  &--with-custom-icon {
    .ds-c-alert__body {
      position: relative;
      font-family: $font-family-rubik;
      @media screen and (min-width: $media-width-sm) {
        margin-left: $spacer-6; // 8px alert left border + 16px alert left padding + 8px alert icon left padding + 16px alert body left padding (when there's a stock icon)
      }
    }
  }
  #{$alert}__icon--custom {
    display: none;
    position: absolute;
    top: 0;
    left: -$spacer-5;
    @media screen and (min-width: $media-width-sm) {
      display: block;
    }
  }
  // a lack of z-index caused some issues with an Info Circle Icon reacting to its hover correctly.
  &--with-z-index {
    z-index: 1;
  }
  &--md-with-icon {
    .ds-c-icon {
      display: none;
      @media screen and (min-width: $media-width-sm) {
        display: block;
      }
    }
  }
}
