.CountyPicker {
  &__form {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--end;
    @extend .ds-u-flex-wrap--wrap;
  }
  &__input {
    @extend .ds-u-margin-right--1;
  }
  &__label {
    @extend .ds-text-heading--xl;
    span:not(.ds-c-field__hint) {
      white-space: nowrap;

      @media screen and (max-width: $media-width-sm) {
        white-space: normal;
      }
    }
  }
  &__submit {
    @extend .ds-text-heading--md;
    @extend .ds-u-padding-y--2;
    @extend .mct-u-width--full;
    @extend .mct-u-md-width--auto;
    @extend .ds-u-serif;
    @extend .ds-u-font-weight--semibold;
  }
}
