.mct-c-sub-section {
  margin-bottom: $spacer-2;
  @media print {
    break-inside: avoid;
  }

  &__top-divider {
    height: $spacer-8;
  }

  &__header {
    @media print {
      // fix spacing issues at top of page
      padding-top: $spacer-3 !important;
    }
  }
  &__icon {
    color: $color-primary;
    font-size: $font-size-xl;
    @media screen and (max-width: $media-width-sm) {
      display: none;
    }
    @media print {
      display: none;
    }
  }
  &__body {
    display: flex;
    @media screen and (max-width: $media-width-sm) {
      flex-direction: column;
    }
    &::before {
      border-left: 4px dotted $color-primary;
      content: "";
      margin-left: $spacer-2;
      margin-right: $spacer-4;
      @media screen and (max-width: $media-width-sm) {
        display: none;
      }
      @media print {
        display: none;
      }
    }
  }
  &__element-description {
    @extend .ds-u-measure--base;
    @extend .ds-u-font-size--lg;
    @extend .ds-u-margin-top--0;
    @extend .ds-u-margin-bottom--3;
  }
  &__print-divider {
    border-color: $color-gray-lighter;
    border-width: 0.5px;
    display: none;
    margin: $spacer-6 0 $spacer-2;
    @media print {
      display: block;
    }
  }
  &--no-border {
    .mct-c-sub-section__body {
      &::before {
        visibility: hidden;
      }
    }
  }
}
