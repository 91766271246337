// This is a hack and violates the semantics of responsive class names in the DS
// Until we come up with a better solution or naming convention. This rule applies
// only to mobile-width, i.e. UNDER the $media-width-sm breakpoint. Responsive utils
// otherwise apply OVER specific breakpoints, and correspond with the breakpoint
// shorthand in the class name
.mct-u-xs-visibility--screen-reader {
  @media screen and (max-width: $media-width-sm) {
    // An exact copy of a class definition in https://github.com/CMSgov/design-system/blob/master/packages/design-system/src/styles/utilities/_display-visibility.scss
    // Needs to be used in a media query, so cannot be an @extend of the DS class
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    // Workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=1241631
    word-wrap: normal;
  }
}
