.mct-c-plan-type-header {
  &__plan-info {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--start;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-lg-flex-direction--row;
    @extend .ds-u-lg-align-items--end;
  }

  &__plan-type {
    @extend .ds-text-heading--2xl;
    @extend .ds-u-margin-bottom--0;

    font-size: 32px;
    // color: var(--color-base);
    // need to specify color because of override.
    color: #404040;
    @media screen and (min-width: $media-width-lg) {
      font-size: 40px;
    }
  }

  &__change-plan {
    @extend .ds-u-padding-left--0;
    @extend .ds-u-lg-margin-left--1;
    @extend .ds-u-lg-padding-bottom--0;
  }

  &__change-location-container {
    @extend .ds-u-margin-top--2;
    @extend .ds-u-lg-margin-top--1;
    @extend .ds-u-margin-bottom--2;

    .ChangeLocation__buttonGroup {
      @extend .ds-u-display--flex;
      @extend .ds-u-flex-direction--row;

      button {
        @extend .ds-u-margin-left--1;
      }
    }

    .ChangeLocation__current-location {
      @extend .ds-u-margin-right--0;
      // color: var(--color-gray-dark);
      // need to specify color because of override.
      color: #5a5a5a; // @TODO - this is `var(--color-gray-dark)` when overrides are removed
      font-size: 18px;
      font-family: $font-family-montserrat;
    }
  }
}
