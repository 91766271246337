.PrintButton {
  @extend .ds-c-button;
  @extend .ds-u-text-decoration--none;
  @extend .ds-u-font-weight--normal;
  @extend .ds-u-border--0;
  @extend .ds-u-color--muted;
  transition: $animation-speed-1 $animation-ease-in-out-expo;
  background-color: transparent;
  &:not(.mct-c-actionsbar__print &) {
    padding: 8px 8px 8px 24px;
  }
  &:hover {
    color: darken($color-gray-dark, 20%) !important;
    background-color: transparent;
  }
}
