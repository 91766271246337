.Collapsible {
  &__trigger {
    @extend .ds-u-padding-right--1;
    @extend .ds-u-text-align--left;
    background: none;
    border: none;
    cursor: pointer;
    color: $color-base;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding-left: unset;
    &--default {
      color: $color-secondary;
      font-weight: 400;
      min-height: 44px;
      text-decoration: underline;
    }

    &:focus {
      @extend .ds-u-focus;
    }
  }

  &__hidden_content {
    visibility: hidden;
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  &__visible_content {
    visibility: visible;
    opacity: 1;
    transition: all 400ms ease-in-out;
  }

  // Matching previous Font Awesome styling
  .mct-c-icon--plus-minus {
    &.ds-c-icon {
      font-size: 11px;
    }
  }
}
