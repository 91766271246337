.mct-c-table {
  $t: &;
  @extend %mct-c-table__container-base;
  position: relative;
  &__header,
  &__cell,
  &__row,
  &__caption {
    @extend %mct-c-table__container-base;
    &-title {
      @extend .ds-u-font-size--base;
      @extend .ds-u-margin-bottom--0;
    }
  }
  &-caption {
    $c: &;
    @extend .ds-c-table__caption;
    @extend .mct-c-table__caption;
    border-style: solid;
    border-inline-width: 0;
    @media screen and (min-width: $breakpoint-lg) {
      border-inline-width: 1px;
    }
    &--alert-message {
      background-color: transparent !important;
    }
    &#{$c}--with-subcaption {
      @extend .ds-u-border-bottom--0;
      @extend .ds-u-padding-bottom--0;
    }
  }
  &__row {
    &:not(&--with-subcaption) {
      @extend .ds-u-border-top--1;
      @extend .ds-u-padding-top--1;
    }
    @extend .ds-u-border-bottom--0;
    @extend .ds-u-padding-bottom--1;
    &--display-lg {
      display: none !important;
      @media screen and (min-width: $breakpoint-lg) {
        display: table-row !important;
      }
    }
    // Filtered rows were rendering with `display:block` and not full width
    // Original display value from CMSDS
    &--filtered {
      display: table-row !important;
      #{$t}__header {
        &--hide-stacked {
          @extend .ds-u-visibility--screen-reader; // Make visible to screen readers, still
        }
      }
    }

    @media print {
      display: table-row !important;
    }
  }
  &:not(.ds-c-table--borderless) {
    @extend .ds-u-border--1;

    @media print {
      border: none !important;
    }
  }
  &__header {
    $h: &;
    background-color: $color-background !important;
    font-family: $font-family-montserrat;
    @extend .ds-u-font-weight--normal;
    .ds-c-table--borderless & {
      @extend .ds-u-border-top--1;
    }
    &-title {
      @extend .ds-u-font-weight--bold;
      @extend .mct-u-text-transform--none;
    }
    &--empty#{$h} {
      @extend .ds-u-fill--gray-lightest;
    }
    &--with-subcaption {
      font-family: $font-family-rubik;
      @extend .ds-u-padding-top--0;
      @extend .ds-u-padding-bottom--3;
    }
    @media screen {
      &--with-global-filter {
        display: table-cell !important;
        @media (min-width: $media-width-lg) {
          display: none !important;
        }
      }
    }
  }
  &__footer {
    @media (max-width: 1024px) {
      border-top: 0 none !important;
    }
  }
  &__cell,
  &__header {
    .ds-c-table--borderless & {
      @extend .ds-u-border-y--0;
      @extend .ds-u-padding-left--0;
    }
    // Backwards media query - Look into how to define with `min-width` instead
    @media screen and (max-width: 1024px) {
      border: 0 none;
    }
    @media print {
      display: table-cell !important;

      &::before {
        content: "" !important;
      }
    }
  }
  &__caption {
    @extend .ds-u-padding-y--2;
    @extend .ds-u-lg-padding-x--3;
    @extend .ds-u-margin-bottom--0;
    @extend .ds-u-font-weight--normal;
    @media screen and (min-width: 1025px) {
      background-color: $color-secondary-lightest;
    }
    &-title {
      @extend .ds-u-font-weight--semibold;
      @extend .ds-u-text-transform--uppercase;
    }
  }
  &__collapsible {
    &-title {
      width: 100%;
      @extend .ds-u-border--1;
    }
    &-container {
      margin-bottom: 1em;
      @media screen and (min-width: calc(#{$media-width-lg} + 1px)) {
        margin-bottom: 0;
      }
    }
  }
  &:not(.ds-c-table--borderless) {
    .mct-c-table {
      &__row {
        @media print {
          border: none !important;
        }
        &--with-subcaption {
          @extend .ds-u-padding-top--0;
        }
      }

      &__caption {
        @extend .ds-u-border-x--1;

        &--with-subcaption {
          @extend .ds-u-border-bottom--0;
          @extend .ds-u-padding-bottom--0;
        }

        @media print {
          border: none !important;
        }
      }

      &__cell {
        @extend .ds-u-border--1;

        @media print {
          border-width: 1px 1px 1px 0 !important;

          &:last-child {
            border-width: 1px 0 1px 0 !important;
          }
        }
      }

      &__header {
        @extend .ds-u-border--1;
        &--with-subcaption {
          @extend .ds-u-padding-x--3;
          @extend .ds-u-padding-top--0;
          @extend .ds-u-font-weight--normal;
          font-family: $font-family-rubik;
        }

        @media print {
          border-width: 1px 1px 1px 0 !important;

          &:last-child {
            border-width: 1px 0 1px 0 !important;
          }
        }
      }

      @media screen and (min-width: $breakpoint-lg) {
        &__header,
        &__row {
          &--with-subcaption {
            border-top-color: $color-secondary-lightest !important;
          }
        }
        &__header {
          &--with-subcaption {
            background-color: $color-secondary-lightest !important;
          }
        }
      }
    }
  }
  // Much of this block controls hiding and displaying of a headerGroup that
  // spans the table and contains a dropdown for filtering rows
  &__head {
    &--with-filter,
    &--with-subcaption {
      display: table-header-group !important;
    }
    #{$t}__row {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        display: table-row;
      }
      &--with-filter,
      &--with-subcaption {
        display: table-row !important;
      }
      &--with-filter {
        @media screen and (min-width: $breakpoint-lg) {
          display: none !important;
        }
        // Hide the filter header row from print
        @media print {
          display: none !important;
        }
      }
    }

    &:not(.ds-u-display--none) {
      @media print {
        display: table-header-group !important;
      }
    }
  }
  &__footnote {
    &-indicators {
      &::after {
        content: "]";
      }
      &::before {
        content: "[";
      }
    }
  }
  &--compact {
    // @TODO - confirm compact styling
    #{$t}__row {
      @extend .ds-u-padding-y--1;
    }
  }
  // Overrides of DS Table styles
  &--is-stacked {
    &.ds-c-table--borderless {
      #{$t}__row {
        &--filtered {
          #{$t}__cell {
            @extend #{$t}__row;
            @extend .ds-u-border-top--1;
          }
        }
      }
    }
    #{$t} {
      &__row-header {
        @extend .ds-u-padding-y--1;
        @extend .ds-u-padding-right--2;
      }
      &__head {
        &--with-alert {
          display: table-header-group !important;
        }
      }
      &__row {
        &--with-alert {
          display: table-row !important;
        }
      }
      &__header {
        &--with-alert {
          @extend .ds-u-padding-x--0;
        }
      }
    }
  }
  @media print {
    break-inside: avoid;

    &__collapsible-title {
      background-color: $color-white !important;
      border: none !important;

      i {
        display: none;
      }
    }

    &__collapsible-container {
      .Collapsible__contentOuter {
        height: auto !important;
      }

      .Collapsible__hidden_content {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .Collapsible__contentInner {
    font-family: $font-family-rubik;
  }
}

// Ensure stacked table filters are displayed correctly
.ds-c-lg-table--stacked {
  @media screen and (max-width: $media-width-lg) {
    .mct-c-table {
      &__head {
        &--with-filter,
        &--with-subcaption {
          display: table-header-group !important;
        }
      }
    }
    th {
      &.mct-c-table__header {
        &--with-global-filter {
          display: table-cell !important;
        }
      }
    }
  }
}
