.LoggedOutFooter {
  @extend .ds-u-fill--gray-lightest;
  @extend .ds-u-padding-x--7;
  @extend .ds-u-padding-y--4;
  margin-top: 96px;
  margin-left: -56px;
  margin-right: -56px;
  margin-bottom: -24px;

  @media screen and (min-width: $media-width-sm) {
    margin-top: 152px;
  }

  ul {
    @extend .ds-u-color--gray;

    @media screen and (min-width: $media-width-lg) {
      columns: 2;
      max-width: $media-width-lg;
    }
  }

  &__actions {
    @extend .ds-u-lg-display--flex;
    @extend .ds-u-lg-align-items--center;
    @extend .ds-u-lg-margin-top--7;
    @extend .ds-u-margin-top--4;
  }

  &__createLink {
    color: $blue-300;
    @extend .ds-u-margin-left--1;
  }

  &__login {
    @extend .ds-u-margin-right--4;
    @extend .ds-u-margin-bottom--2;
    @extend .ds-u-lg-margin-bottom--0;
    min-width: 200px;
  }
}
