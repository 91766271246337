.mct-c-plan-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer-3 0;
  margin-block: 0;
  padding-inline: 0;
  & > li {
    list-style-type: none;
  }
  @media screen and (max-width: $media-width-sm) {
    flex-direction: column;
    gap: 0;
  }
  @media print {
    flex-direction: column;
  }
}
