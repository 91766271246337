.ViewPrescriptions {
  position: relative;

  &__linkButton {
    @extend .ds-u-padding--0;
    @extend .ds-u-margin-top--3;
    font-size: $font-size-md;
  }

  &__prescriptionDetail {
    @extend .ds-u-margin--0;
    @extend .ds-u-color--gray-light;
  }

  &__prescriptionName {
    @extend .ds-text-heading--xl;
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-margin--0;
    color: $black-500 !important;
    @extend .ds-u-md-margin-bottom--1;
    @extend .ds-u-word-break;
  }

  &__prescriptionCardText,
  &__prescriptionCardLabel {
    @extend .ds-u-margin--0;
    @extend .ds-u-md-margin-bottom--1;
    color: $color-gray-dark;

    font-size: 18px;
    @media screen and (min-width: $media-width-sm) {
      font-size: 16px;
    }
    @media screen and (min-width: $media-width-md) {
      font-size: 18px;
    }
  }

  &__prescriptionCardLabel {
    @extend .ds-text-heading--lg;
    @extend .ds-u-font-weight--semibold;
  }

  &__prescriptionCardText {
    color: $color-gray-light;
    // legacy design decision pre medicare ds 7.0.0
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  &__footer {
    @extend .ds-u-margin-y--6;
    @extend .ds-u-padding-top--3;
    @extend .ds-u-border-top--2;
  }

  &__prescriptionHeading,
  &__listCardsContainer {
    max-width: $media-width-lg;
  }

  &__prescriptionCard {
    @extend .ds-u-md-padding--4;
    @extend .ds-u-padding--2;
    @extend .ds-u-margin-bottom--2;
  }

  &__toast {
    @extend .ds-u-fill--secondary-lightest;
    @extend .ds-u-padding--2;
  }

  @media print {
    &__listCards {
      li {
        border-bottom: 1px solid $color-gray-lighter;
      }
    }
    &__prescriptionCard {
      box-shadow: none;
      border: none;
      margin: 0 !important;
      page-break-inside: avoid;
      h3,
      h4,
      p {
        font-size: 14px;
        line-height: 1.3;
      }
      .ds-l-sm-col--2 {
        display: inline-block;
        width: 15%;
        vertical-align: top;
      }
      .ds-l-sm-col--3 {
        display: inline-block;
        width: 25%;
        vertical-align: top;
      }
      .ds-l-sm-col--4 {
        display: inline-block;
        width: 35%;
        vertical-align: top;
      }
    }
  }
}

.ManagePrescriptions {
  .PrintButton {
    @extend .ds-u-padding--1;
  }
}
