.Paying {
  &__section {
    & .ds-c-fieldset > .ds-c-label {
      max-width: 636px !important;
    }
  }
  & .EnrollmentFormPage__buttons {
    border: none;
  }
}
