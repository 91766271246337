%mct-l-container--pace-pap-spap {
  max-width: 100ex !important;
}

%mct-l-flush-to-edge {
  margin-left: -56px;
  margin-right: -56px;

  @media screen and (max-width: $media-width-sm-max) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

%mct-l-sm-flush-to-edge {
  @media screen and (max-width: $media-width-sm-max) {
    border-left: 0 !important;
    border-right: 0 !important;
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
}

%mct-c-table__container-base {
  border-color: $color-gray-lighter !important;
  border-width: 1px;
}

%mct-c-table__caption-base {
  @extend .ds-u-padding-y--2;
  @extend .ds-u-padding-x--0;
  @extend .ds-u-lg-padding-x--3;
}
