.sortSelector__container {
  @extend .ds-l-lg-col--6;
  @extend .ds-u-lg-display--flex;
  @extend .ds-u-lg-justify-content--end;
  @extend .ds-u-lg-align-items--center;
  @extend .ds-u-margin-bottom--3;
  @extend .ds-u-lg-margin-bottom--0;
  @extend .ds-u-padding-x--0;
  @extend .ds-u-lg-padding-x--2;

  .ds-c-field {
    min-height: 46px;
    width: 386px;

    @media screen and (max-width: $media-width-lg) {
      width: 100%;
    }
  }
}
