.SanctionedPlanCard {
  @extend .m-c-card;
  @extend .ds-u-lg-padding--2;
  @extend .ds-u-margin-bottom--2;
  @extend .ds-u-lg-display--flex;
  @extend .ds-u-justify-content--start;
  margin-left: -48px !important;
  list-style-type: none;

  &__summary {
    @extend .ds-l-md-col--5;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--start;
  }

  &__planName {
    @extend .ds-text-heading--xl;
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-margin-y--0;
  }

  &__orgName,
  &__planId {
    @extend .ds-u-color--gray-light;
    font-size: 16px !important;
  }

  &__planId {
    font-size: 14px !important;
  }

  &__heading {
    @extend .ds-text-heading--lg;
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-text-transform--uppercase;
    @extend .ds-u-border-bottom--2;
    width: 100%;
  }

  &__content {
    @extend .ds-l-md-col--7;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    width: 100%;
  }

  &__phoneNumber {
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-margin-y--1;
    font-family: $font-family-montserrat;
    font-size: 16px !important;
  }
}
