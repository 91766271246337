.mct-c-summary-page-pharmacy-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer-3 0;
  margin-block: 0;
  padding-inline: 0;

  &__pharmacy-network-label-help-drawer-toggle {
    @extend .ds-u-margin-top--3;
    margin-bottom: 0 !important;
  }

  li {
    list-style: none;
    display: flex;
    @media screen and (max-width: $media-width-sm) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: $media-width-sm) {
    flex-direction: column;
    gap: 0;
  }

  @media print {
    flex-direction: column;
    gap: 0;
    margin-left: $spacer-3;
  }
}
