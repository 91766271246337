.#{$rt-namespace} {
  // $rt-namespace is defined in react-toastify like this:
  // $rt-namespace: 'Toastify';
  // https://github.com/fkhadra/react-toastify/blob/main/scss/_variables.scss#L1

  &__toast {
    @extend .ds-u-sans;
    color: $color-black !important;
    --toastify-color-light: $color-primary-light;

    &-body {
      padding: 0;
    }

    &--error {
      background-color: $color-error-lightest !important;
    }

    &--success {
      background-color: $color-success-lightest !important;
    }

    &--warn {
      background-color: $color-warn-lightest !important;
    }

    &--info {
      background-color: $color-secondary-lightest !important;
    }
  }

  &__close-button {
    color: $color-black !important;
  }

  &__progress-bar {
    &--error {
      background-color: $color-error !important;
    }

    &--success {
      background-color: $color-success !important;
    }

    &--warn {
      background-color: $color-warn !important;
    }

    &--info {
      background-color: $color-secondary !important;
    }
  }

  &__zoom-enter {
    // animation-name: #{$rt-namespace}__zoomIn;
    animation-duration: 400ms;
  }

  &__zoom-exit {
    // animation-name: #{$rt-namespace}__zoomOut;
    animation-duration: 400ms;
  }
}
