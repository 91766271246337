.StatePicker {
  &__input {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--end;
    @extend .ds-u-flex-wrap--wrap;
  }

  .ds-c-field--statePicker {
    @extend .ds-u-margin-right--1;
  }

  &__label {
    span:not(.ds-c-field__hint) {
      white-space: nowrap;

      @media screen and (max-width: $media-width-sm) {
        white-space: normal;
      }
    }
  }

  button {
    @extend .ds-text-heading--lg;
    font-weight: 600;
    padding: 14px;
  }
}

.SelectStateModal {
  & .ds-c-fieldset {
    @extend .ds-u-margin-top--2;
  }

  & aside {
    @extend .ds-u-margin-top--1;

    & > button {
      width: 146px;
    }
  }
}
