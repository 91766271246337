.mct-c-headerLogo {
  @extend .ds-u-display--flex;
  @extend .ds-u-align-items--center;
}

.mct-c-headerLogo__image {
  max-height: 46px - $base-font-size;
  height: 46px - $base-font-size;
  max-width: 199px;
  fill: $color-primary;

  @media screen and (min-width: $media-width-sm) {
    max-height: 65px - ($base-font-size * 2);
    height: 65px - ($base-font-size * 2);
  }
}

.mct-c-header__action {
  @extend .ds-u-padding-y--0;
  @extend .ds-u-padding-x--0;
  @extend .ds-u-margin-y--0;
  @extend .ds-u-text-decoration--none;
  @extend .ds-u-border--0;

  @media screen and (min-width: $media-width-sm) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.m-c-navbar__section:first-child {
  @extend .ds-u-padding-y--1;

  @media screen and (min-width: $media-width-sm) {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}

.m-c-navigationMenu__container {
  @extend .ds-l-sm-col--12;
}

.m-c-navigationMenu__item {
  height: 31px;
  border-right: 0 !important;
}

.m-c-navbar {
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  @extend .ds-u-padding-y--3;
  @media screen and (max-width: $media-width-md) {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
    padding-left: 16px !important;
  }
}

.m-c-navbar--inverse .m-c-navbar__productName,
.m-c-navigationMenu__item a {
  @extend .ds-u-font-weight--normal;
}

.m-c-navigationMenu__item {
  height: auto;
}

.m-c-navbar__productName {
  @extend .ds-u-text-decoration--none;
  @extend .ds-u-display--none;
  @extend .ds-u-md-display--block;
}

a.mct-c-headerLogo svg:hover {
  fill: $color-primary-darker;
}

.m-c-navigationMenu__mobileToggle {
  top: -52px;
}

.m-c-globalHeader--dark {
  .m-c-globalHeader__openMenu {
    @media screen and (max-width: $media-width-md) {
      svg {
        color: $color-white !important;
      }
    }
  }
}

/**
 * Override global header to create new stacking context with specific, low z-index. 
 * Elements within the GH create their own contexts, making it impossible to 
 * overlay by specifying a higher z-index value.
 * With this override, the help drawer will always overlay the header.
 * We do not control GH CSS.
 */
#ch-root {
  position: relative;
  z-index: 2;

  /** reset the paragraph spacing */
  .usa-banner__header-text {
    margin-bottom: 0px !important;
  }
}
