.MedigapPlanDetails {
  &__viewPolicies {
    @media screen and (min-width: $media-width-md) {
      margin-left: 112px;
    }
    align-self: flex-start !important;
  }

  &__viewPoliciesButton {
    @extend .ds-u-margin-top--2;
    white-space: nowrap;
  }

  &__header {
    @extend .ds-u-md-display--flex;
    @extend .ds-u-md-justify-content--between;
    @extend .ds-u-margin-bottom--4;
    margin-top: 71px;

    @media print {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }

    h1 {
      font-size: 62px !important;
      line-height: 79px !important;
      @extend .ds-u-md-margin--0;

      @media print {
        font-size: 32px !important;
        line-height: 47px !important;
      }
    }

    @media screen and (max-width: $media-width-sm) {
      h1 {
        font-size: 36px !important;
        line-height: 47px !important;
      }
    }
  }

  &__section {
    @extend .ds-u-md-display--flex;
    @extend .ds-u-border-top--2;
    @extend .ds-u-padding-y--3;
    @extend .ds-u-md-padding-top--4;
    @extend .ds-u-md-padding-y--7;

    @media print {
      padding-top: 16px !important;
      padding-bottom: 8px !important;
    }
  }

  &__sectionTitleTooltip {
    @extend .ds-text-heading--md;
    font-size: 16px !important;
  }

  &__sectionTitle {
    margin-right: 116px;
    width: 200px;
    max-width: 200px;
    @extend .ds-u-margin-bottom--3;
    @extend .ds-text-heading--sm;
  }

  &__table {
    font-size: 16px !important;
  }

  &__tableContainer {
    @media print {
      .Tooltip__trigger {
        text-decoration: none !important;
        border-bottom: none !important;
      }
    }
  }

  &__tableTitle {
    @extend .ds-text-heading--md;
    @extend .ds-u-margin-bottom--3;
    font-size: 16px !important;
    &:not(:first-child) {
      @extend .ds-u-md-margin-top--7;
    }
  }

  &__tableRow {
    font-size: 16px !important;
    @extend .ds-u-md-display--flex;
    @extend .ds-u-margin-bottom--3;

    @media print {
      margin-bottom: 8px !important;
    }
  }

  &__tableDataProperty {
    margin-right: 61px;
    width: 250px;
    max-width: 250px;
    min-width: 250px;

    button {
      font-size: 16px !important;
      @media screen and (max-width: $media-width-sm) {
        font-weight: 600 !important;
      }
    }

    @media screen and (max-width: $media-width-sm) {
      font-weight: 600 !important;
    }
  }
}
