.mct-c-what-youll-pay {
  font-family: $font-family-montserrat !important;

  &__contents {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-lg-flex-direction--row;
  }

  // Only applies if title is shown, not on Summary in Featured Plan
  &__titleContainer {
    @extend .ds-u-border-bottom--1;
    @extend .ds-u-lg-padding-right--2;
    @extend .ds-u-lg-padding-top--1;
    @extend .ds-u-padding-bottom--2;
    @extend .ds-u-lg-padding-bottom--2;
    @extend .ds-u-lg-text-align--right;
    border-color: $color-white;

    @media screen and (min-width: $media-width-lg) {
      border-bottom: none !important;
      border-right: 1px solid $color-white;
    }
  }

  &__features {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    margin-block-start: 0;
    margin-block-end: 0;

    @media print {
      flex-direction: row !important;
      justify-content: space-between !important;
    }
  }

  &__feature {
    @extend .ds-u-display--flex;
    @extend .ds-u-margin-top--0;
    @extend .ds-u-lg-margin-right--5;
    @extend .ds-u-margin-bottom--2;
    @extend .ds-u-lg-margin-bottom--0;

    @media print {
      flex-direction: column !important;
    }

    &-label {
      @extend .ds-u-lg-margin-bottom--2;
    }

    &-value {
      margin-inline-start: 0;

      @extend .ds-u-margin-left--3;
      @extend .ds-u-lg-margin-left--0;

      @media print {
        margin-left: 0 !important;
      }
    }
  }

  &__cost {
    @extend .ds-u-font-weight--bold;

    @media print {
      font-size: var(--font-size-xl) !important;
    }
  }

  &__view-drug-coverage {
    @extend .ds-u-display--block;
    @media screen and (min-width: $media-width-lg) {
      display: inline !important;
      margin-left: $spacer-1;
    }
  }

  &__view-drug-coverage-link {
    font-family: $font-family-rubik !important;
    color: var(--color-success-light) !important;
    text-decoration-color: var(--color-success-light) !important;
  }

  // Plan Details specific styles
  .mct-p-plan-details & {
    @extend .ds-u-lg-margin-bottom--4;
    @extend %mct-l-flush-to-edge;
    background-color: $color-secondary;
    color: $color-white;

    @media print {
      background-color: $color-white;
      color: $color-gray-darker;
    }

    &__contents {
      @extend .ds-u-margin-x--2;
      @extend .ds-u-sm-margin-x--7;
      @extend .ds-u-padding--3;

      @media print {
        padding: 0 !important;
      }
    }

    &__feature {
      @extend .ds-u-lg-flex-direction--column;
      &-label {
        min-width: 10em;
        width: 10em;
        max-width: 10em;
        @media screen and (min-width: $media-width-lg) {
          min-width: auto;
          width: auto;
          max-width: none;
        }
      }
    }

    &__features {
      @extend .ds-u-padding-top--2;
      @extend .ds-u-padding-bottom--2;
      @extend .ds-u-lg-padding-left--2;
      @extend .ds-u-lg-flex-direction--row;
    }

    &__cost {
      font-size: 18px !important;
      @media screen and (min-width: $media-width-lg) {
        font-size: 36px !important;
      }
    }
  }

  // Feature Card specific styles

  .mct-c-featured-plan & {
    &__cost {
      line-height: 40px; // @TODO - per Sketch, confirm
      @extend .ds-u-text-align--right;
      @extend .ds-u-xl-text-align--left;
      @extend .ds-u-font-size--2xl;
    }
    &__feature {
      column-gap: $spacer-2;
      justify-content: space-between;
      margin-right: 0 !important;
      @extend .ds-u-xl-flex-direction--column;
      @media screen and (min-width: $media-width-xl) {
        justify-content: unset;
      }
      @media screen and (min-width: $media-width-lg) {
        column-gap: $spacer-4;
      }
      &-label {
        &--single-feature {
          @extend .ds-u-margin-bottom--2;
        }
      }
      &-value {
        justify-self: flex-end;
        margin-left: 0 !important;
        @media screen and (min-width: $media-width-xl) {
          align-self: flex-start;
        }
        &--single-feature {
          align-self: flex-start;
        }
      }
      &--single-feature {
        @extend .ds-u-flex-direction--column;
        @extend .ds-u-margin-bottom--0;
      }
      .mct-c-benefit {
        display: flex !important;
        flex-direction: column;
        align-items: flex-end;
        @media screen and (min-width: $media-width-xl) {
          align-items: flex-start;
        }
      }
      &-container {
        &:not(:first-of-type) {
          // faux gap dividers
          &::before {
            align-self: stretch;
            border: 1px solid var(--color-gray-lighter);
            content: "";
            margin: -12px 0 0;
            position: absolute;
            width: 100%;
            @media screen and (min-width: $media-width-xl) {
              border: 0 none;
              display: none;
              margin: 0;
              width: 0;
            }
          }
        }
      }
    }
    &__features {
      column-gap: $spacer-4;
      overflow: hidden;
      position: relative;
      row-gap: $spacer-4;
      width: 100%;
      @extend .ds-u-xl-flex-direction--row;
    }
  }
}
