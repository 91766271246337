.MedigapLandingPage {
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-align-items--center;
  @extend .ds-u-fill--white;
  margin-bottom: -48px;

  &__link {
    @extend .ds-u-margin-y--2;
  }

  &__hero {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-justify-content--start;
    background: none;
    background-color: #41716a;
    background: url(../../../../assets/medigap-bg_1x.jpg);
    background-clip: border-box;
    background-position: 100% 0%;
    background-size: cover;
    width: 100%;
    padding-bottom: 64px !important;
  }

  &__heroContent {
    @extend .ds-u-padding-top--7;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--center;
  }

  &__heroHeader {
    @extend .ds-u-margin-y--0;
    @extend .ds-u-margin-x--2;
    @extend .ds-base--inverse;
    @extend .ds-u-fill--transparent;
    @extend .ds-text-heading--3xl;
    text-align: center;

    @media screen and (min-width: $media-width-md) {
      margin-top: 48px;
      margin-bottom: 8px !important;
    }
  }

  &__heroSubheader {
    @extend .ds-u-margin-x--2;
    padding-bottom: 72px;
    @extend .ds-u-color--white;
    @extend .ds-text-heading--lg;
    font-weight: 400 !important;
    line-height: 28px;
    text-align: center;

    @media screen and (min-width: $media-width-md) {
      width: 750px;
    }
  }

  &__content {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--center;
    @extend .ds-u-margin-x--2;
    @extend .ds-u-padding-x--2;
    margin-top: -64px;
    margin-bottom: 96px;

    @media screen and (min-width: $media-width-md) {
      width: 678px;
    }

    &__heading {
      @extend .ds-u-margin--0;
      @extend .ds-u-margin-bottom--4;
      @extend .ds-u-margin-top--2;
      color: $color-gray-dark;
    }
  }

  &__card {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-padding-x--2;
    @extend .ds-u-padding-bottom--3;
    width: 100%;

    @media screen and (min-width: $media-width-md) {
      max-width: 678px;
      padding-left: 90px;
      padding-right: 90px;
      padding-bottom: 80px;

      & input {
        margin-right: 8px;
      }
    }

    &:first-child {
      @extend .m-c-card;
      @extend .ds-u-margin-bottom--4;
    }
  }

  &__statePicker {
    & button {
      width: 180px;
    }

    @media screen and (max-width: $media-width-sm) {
      width: 100%;
      & .StatePicker__input {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap !important;
      }

      & .ds-c-field--statePicker {
        margin: 0 !important;
        width: 100%;
      }

      & input {
        width: 100%;
      }

      & button {
        margin-top: 16px;
        width: 100%;
      }
    }
  }

  &__list {
    @extend .ds-u-margin-top--0;
    @extend .ds-u-padding--0;

    @media screen and (min-width: $media-width-md) {
      width: 590px;
    }
  }

  &__item {
    @extend .ds-u-display--flex;
    @extend .ds-u-margin--0;
    @extend .ds-u-margin-bottom--3;
    @extend .ds-u-padding--0;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: $media-width-md) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &__listNumber {
      @extend .ds-u-margin-top--2;
      @extend .ds-u-margin-bottom--1;
      @extend .ds-text-heading--3xl;
      font-size: 30px !important;
      color: $color-secondary-light;

      @media screen and (min-width: $media-width-md) {
        white-space: nowrap;
        flex-basis: 20%;
        flex-shrink: 0;
      }
    }

    &__itemContent {
      border-top: solid 1px $color-secondary-light;
      @extend .ds-u-padding-top--3;
      display: block;

      @media screen and (min-width: $media-width-md) {
        border: none;
        border-left: solid 1px $color-secondary-light;
        margin-top: 8px;
        padding-top: 8px;
        padding-left: 32px;
      }

      & > strong {
        @extend .ds-u-margin-bottom--2;
        font-weight: 600;
      }
    }
  }
}
