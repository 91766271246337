.StickyNav {
  @extend %mct-l-flush-to-edge;
  @extend .ds-u-fill--white;
  @extend .ds-u-border-top--1;
  @extend .mct-hide-print;
  border-bottom: 5px solid $color-primary;
  font-family: $font-family-montserrat;
  position: sticky;
  top: -2px;
  z-index: 2;

  @media screen and (min-width: $media-width-lg) {
    border-bottom: 1px solid $color-gray-lighter;
  }

  &__mobile_toggle {
    @extend .ds-u-lg-display--none;
    @extend .ds-c-button--transparent;
    @extend .ds-u-text-align--left;
    @extend .ds-u-padding-left--5;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-text-decoration--none;
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-color--gray;
    border-radius: 0;
    font-family: $font-family-montserrat;
    width: 100%;

    &[aria-expanded="true"] {
      @extend .ds-u-border-bottom--1;
    }

    &:hover {
      color: $color-primary !important;
      background-color: white !important;
    }
  }

  &__highlighter {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: $color-primary;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);

    @media screen and (max-width: $media-width-lg) {
      display: none;
    }
  }

  &__list {
    @extend .ds-u-margin-x--2;
    @extend .ds-u-sm-margin-x--7;
    @extend .ds-c-list--bare;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-lg-flex-direction--row;
    max-height: 80vh;
    overflow: auto;
    transition: max-height 0.5s ease-in-out;

    &.mobile_closed {
      max-height: 0;
      margin-top: -2px;

      @media screen and (min-width: $media-width-lg) {
        max-height: 350px;
        margin-top: 0;
      }
    }

    li {
      @extend .ds-u-padding-x--1;
      @extend .ds-u-lg-padding-x--3;
      @extend .ds-u-padding-y--2;
      @extend .ds-u-lg-padding-y--1;

      a {
        @extend .ds-u-text-decoration--none;
        @extend .ds-u-display--flex;
        @extend .ds-u-align-items--center;
        @extend .ds-u-color--gray;
        @extend .ds-u-text-align--center;
        height: auto;

        @media screen and (min-width: $media-width-lg) {
          height: 48px;
        }

        &:hover {
          @extend .ds-u-color--primary;
        }

        &.active {
          @extend .ds-u-font-weight--semibold;
          color: $color-gray-darker !important;
        }
      }
    }
  }

  &__background {
    @extend .ds-u-fill--gray-light;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    transition: opacity 0.5s;
    z-index: 2;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    @media screen and (min-width: $media-width-lg) {
      display: none;
    }
  }

  &__link {
    &:focus {
      @extend .ds-u-focus;
    }
  }
}

.no-scroll-on-mobile {
  @media screen and (max-width: $media-width-lg) {
    overflow: hidden;
  }
}
