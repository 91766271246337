.mct-c-extra-help-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer-3 0;
  margin-bottom: $spacer-5;
  @media screen and (max-width: $media-width-sm) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 0;
  }
  @media print {
    flex-wrap: nowrap;
  }
  .mct-c-extra-help-card:last-of-type {
    @media print {
      margin: 0;
    }
  }
}
