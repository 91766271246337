.mct-c-summary-page-plan-card {
  width: 355px;
  min-height: 324px;
  margin-right: $spacer-3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: $media-width-sm) {
    justify-content: flex-start;
    margin: 0 0 $spacer-3;
    min-height: auto;
    width: auto;
  }
  @media print {
    border: none;
    box-shadow: none;
    display: block;
    margin: 0 $spacer-3 0;
    min-height: auto;
    padding: 0 !important;
    width: 300px;
  }
  &__header {
    margin-bottom: $spacer-2;
  }
  p {
    margin: 0 0 $spacer-1;
  }
  &__plan-header {
    font-size: $font-size-lg;
    @extend .ds-u-font-weight--normal;
  }
  &__id {
    color: $color-gray-light;
  }
  &__footer {
    @media screen and (max-width: $media-width-sm) {
      margin-top: $spacer-2;
      display: flex;
    }
  }

  // Medigap Plan Card
  &-medigap {
    &__header {
      @extend .ds-u-margin-bottom--1;
    }
    &__dates-effective {
      &-date {
        @extend .ds-u-margin-left--0;
      }
      &-date:not(&-date:last-of-type) {
        margin-bottom: $spacer-2;
      }
      &-label {
        @extend .ds-u-text-transform--capitalize;
      }
    }
  }
}
