.mct-c-pharmacy-selection-map {
  width: 100%;
  height: 100%;

  canvas {
    height: 100%;
  }
}

// Map markers
@mixin tail-negative-top-margin {
  &::before {
    margin-top: calc(-1 * var(--tail-top-inset));
  }
  &::after {
    margin-top: calc(-1 * (var(--tail-top-inset) + 4px));
  }
}

%map-marker-base {
  @extend .ds-u-font-weight--normal;
  align-items: center;
  border-radius: 9999px;
  border-width: var(--marker-border-width);
  border-style: solid;
  display: inline-flex;
  font-family: $font-family-montserrat;
  font-size: var(--base-font-size);
  gap: var(--pharmacy-number-cost-gap);
  justify-content: center;
  line-height: var(--base-line-height);
  position: relative;
  transform-style: preserve-3d;
  @include tail-negative-top-margin;
}

%map-marker-tail {
  aspect-ratio: 1 / cos(30deg);
  clip-path: polygon(50% 100%, 100% 0, 0 0);
  content: "";
  left: 50%;
  mask: linear-gradient(0deg, #0000 calc(3 * var(--tail-radius) / 2), #000 0),
    radial-gradient(
      var(--tail-radius) at 50% calc(100% - 2 * var(--tail-radius)),
      #000 98%,
      #0000 101%
    );
  position: absolute;
  top: 100%;
  width: var(
    --tail-width
  ); /* Judgement call here, affects total size of marker tail */
}

.mct-c-map-marker {
  --marker-padding: 6px;
  --marker-border-width: 2px;
  --marker-border-color: #a6a6a6;
  --base-font-size: 16px;
  --base-line-height: 20px;
  --pharmacy-number-padding: 2px;
  --color-gray-dark: #5a5a5a;
  --pharmacy-number-background: #d9d9d9;
  --pharmacy-number-cost-gap: 6px;
  --marker-base-tail-radius: 2px;
  --tail-z-index: 0px;
  $base: &;
  @extend %map-marker-base;
  background-color: $color-white;
  border-color: $color-primary;
  color: $color-gray;
  padding: var(--marker-padding);
  min-width: calc(
    calc(2 * (var(--marker-padding) + var(--marker-border-width))) +
      var(--base-line-height)
  );

  &::before,
  &::after {
    @extend %map-marker-tail;
  }

  &::before {
    --tail-radius: calc(
      var(--marker-base-tail-radius) * 2
    ); /* border-radius: ;*/
    background-color: $color-primary;
    transform: translate3d(-50%, 0, -1px);
  }

  &::after {
    --tail-radius: var(--marker-base-tail-radius); /* border-radius: ;*/
    background-color: $color-white;
    transform: translate(-50%);
  }

  &:not(&--with-costs) {
    &::before,
    &::after {
      --tail-width: 28px; // fits with the size of the default number-only circle
    }

    --tail-top-inset: 4px;

    &#{$base}--selected,
    &#{$base}--source-location,
    &:hover {
      --tail-top-inset: 5px;
    }
    &#{$base}--selected {
      &::after,
      &::before {
        --tail-width: 29px;
      }
    }
    &:hover,
    &#{$base}--source-location {
      &::after,
      &::before {
        --tail-width: 31px;
      }
    }
  }

  &:not(&--source-location) {
    cursor: pointer;
  }

  &:hover:not(&--source-location) {
    --grow-padding: calc(var(--marker-padding) + 2px);
    padding: var(--grow-padding);
    min-width: calc(
      calc(2 * (var(--grow-padding) + var(--marker-border-width))) +
        var(--base-line-height)
    );
  }

  &--selected {
    transform: scale(105%);
  }

  &:focus {
    outline: none;
  }

  &--selected,
  &--source-location {
    border-color: $color-white;
    background-color: $color-primary;
    color: $color-white;

    &::before {
      background-color: $color-white;
    }

    &::after {
      background-color: $color-primary;
    }
  }

  &__cost {
    color: var(--color-gray-dark);
    margin-right: var(--pharmacy-number-cost-gap);
  }

  .mct-c-map-icon,
  &__cost,
  &__number {
    z-index: 2;
  }

  &#{$base} {
    &--with-costs {
      --tail-top-inset: 0px;
      --marker-padding: 2px;
      border-color: var(--marker-border-color);
      &::after,
      &::before {
        --tail-width: #{$spacer-2};
      }
      &::before {
        background-color: var(--marker-border-color);
      }
      #{$base} {
        &__number {
          border-radius: 9999px;
          background-color: var(--color-gray-lighter);
          color: var(--color-black);
          padding: var(--pharmacy-number-padding);
          text-align: center;
          min-width: calc(
            calc(2 * var(--pharmacy-number-padding)) + var(--base-line-height)
          );
        }
      }
      &#{$base} {
        &--selected {
          background-color: $color-white;
          border-color: $color-primary;
          color: $color-black;
          &::before {
            background-color: $color-primary;
          }

          &::after {
            background-color: $color-white;
          }

          #{$base} {
            &__number {
              background-color: $color-primary;
              color: $color-white;
            }
            &__cost {
              font-size: 18px;
              color: $color-gray-dark;
            }
          }
        }
      }
    }
  }
}

.mct-c-map-icon {
  &--home-location,
  &--current-location {
    fill: $color-white;
  }
  &--mapbox-location {
    fill: $color-primary;
  }
}

// The element returned from MapboxGl's `marker.getElement()` method
.mct-c-mapboxgl-marker {
  &:focus {
    @extend .ds-u-focus;
    position: absolute;
  }
}
