.mct-c-select-with-button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: $spacer-2;
  @media screen and (min-width: $media-width-md) {
    align-items: flex-end;
    flex-direction: row;
  }
  // The `Dropdown` component, with label and other messaging
  &__dropdown {
    flex-basis: 100%;
    @media screen and (min-width: $media-width-md) {
      flex-basis: content;
    }
  }
  // The select input inside the `Dropdown` component
  &__select {
    min-width: 100%;
    @media screen and (min-width: $media-width-md) {
      min-width: content;
    }
  }
  &__button {
    // half of one side of the padding + margin + border of the select
    margin-bottom: calc(
      (var(--spacer-2) + var(--spacer-half) + (var(--spacer-half) / 2)) / 2
    );
  }
}
