.AddPrescription {
  min-height: 460px;

  &__actions {
    @extend .ds-u-margin-top--6;
    @extend .ds-u-padding-top--4;
    @extend .ds-u-border-top--2;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-md-flex-direction--row;
    @extend .ds-u-align-items--stretch;
  }
}
