.mct-c-personal-info {
  &__race-ethnicity {
    @extend .ds-u-margin-top--3;

    .ds-c-choice-wrapper {
      @extend .ds-u-margin-y--3;
    }
    .ds-c-field__hint {
      @extend .ds-u-margin-y--1;
      font-size: $font-size-md;
      color: $color-gray;
    }

    .ds-c-fieldset {
      .ds-c-field__hint {
        max-width: 35rem;
      }
    }
  }
}
