.StarRatingsSection {
  .PlanDetailsSection__heading {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-md-flex-direction--row;

    h2 {
      @extend .ds-u-margin--0;
    }
  }

  &__heading {
    @extend .ds-u-font-weight--bold;
    @extend .ds-u-margin-bottom--1;
    @extend .ds-u-md-margin-bottom--0;
    font-family: $font-family-rubik;
    font-size: $font-size-md;
  }

  &__expandAll {
    @extend .ds-text-heading--md;
    @extend .ds-u-border--0;
    @extend .ds-u-text-align--left;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-padding-x--0;
    @extend .ds-u-md-padding-y--1;
    @extend .ds-u-margin--0;
    @extend .ds-u-fill--transparent;
    color: $color-gray-darker;
    font-family: $font-family-montserrat;
  }

  .OriginalMedicareStarRatingsToggle {
    @extend .ds-u-margin-left--1;
    @extend .ds-u-md-margin-left--3;
    @extend .ds-u-text-align--left;
  }

  .StarRatingRow {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-wrap--wrap;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-align-items--center;
    @extend .ds-u-border-x--1;
    @extend .ds-u-border-bottom--1;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-padding-left--2;
    @extend .ds-u-padding-right--2;

    &__blue {
      @extend .ds-u-fill--secondary-lightest;
      @extend %mct-l-sm-flush-to-edge;
      @extend .ds-u-border-top--1;

      @media print {
        background-color: $color-white !important;
      }
    }

    &__performerRow {
      @extend %mct-l-sm-flush-to-edge;
      @extend .ds-u-border-bottom--0;
    }
  }

  .StarRatingExpandableRow {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;

    &__toggle {
      @extend .ds-u-display--flex;
      @extend .ds-u-align-items--center;
      @extend .ds-u-justify-content--between;
      @extend .ds-u-padding--2;
      @extend .ds-u-margin--0;
      @extend .ds-u-border-x--1;
      @extend .ds-u-border-bottom--1;
      @extend .ds-u-border-top--0;
      @extend .ds-u-fill--white;
      @extend .ds-u-text-align--left;
      color: $color-base;
      cursor: pointer;
      border-radius: 0;
      flex-grow: 1;

      .mct-c-icon--plus-minus {
        font-size: 12px !important;
      }

      .StarRating__message {
        @media screen and (max-width: $media-width-sm-max) {
          font-size: 18px !important;
        }
      }

      &__green {
        @extend %mct-l-sm-flush-to-edge;
        background-color: $color-primary-lightest !important;

        @media print {
          background-color: $color-white !important;
        }

        &[aria-expanded="false"] {
          @extend .ds-u-border-bottom--0;
        }

        .StarRatingsSection__heading {
          @extend .ds-u-margin-bottom--0;
        }
      }
    }

    & .StarRatingRow {
      @media screen and (max-width: $media-width-sm-max) {
        border-left: 0 !important;
        border-right: 0 !important;
      }
    }
    & .StarRatingExpandableRow {
      &:last-child {
        & .StarRatingExpandableRow__toggle[aria-expanded="false"] {
          @extend .ds-u-border-bottom--0;
        }
      }

      & .StarRatingExpandableRow__toggle {
        @extend .ds-u-padding-left--2;
        @extend .ds-u-md-padding-left--3;

        @media screen and (max-width: $media-width-sm-max) {
          border-left: 0 !important;
          border-right: 0 !important;
        }
      }
    }

    @media print {
      page-break-inside: avoid;
    }
  }

  .StarRatingList {
    @extend .ds-u-border--1;
    @extend .ds-u-border-top--0;
    @extend .ds-u-margin--0;
    @extend .ds-u-padding-left--3;
    @extend .ds-u-md-padding-left--4;
    @extend .ds-u-padding-right--2;

    @media screen and (max-width: $media-width-sm-max) {
      background-color: rgba($color-gray-lightest, 0.5);
      border-left: 0 !important;
      border-right: 0 !important;
      border-top: 1px solid $color-secondary-light !important;
      border-bottom: 1px solid $color-secondary-light !important;
    }
  }

  .StarRatingListItem {
    @extend .ds-u-padding-y--2;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-md-flex-direction--row;
    @extend .ds-u-align-items--start;
    @extend .ds-u-justify-content--between;

    &:not(:last-child) {
      @extend .ds-u-border-bottom--1;
    }
  }
}
