.OriginalMedicareStarRatingsTable {
  width: 100%;

  th {
    @extend .ds-u-fill--secondary-lightest;
    @extend .ds-u-font-weight--semibold;
    @extend .ds-u-padding-y--2;
  }

  td {
    @extend .ds-u-padding-y--1;
    @extend .ds-u-padding-x--2;
  }

  td:first-child {
    min-width: 150px;
  }

  td:last-child {
    @extend .ds-u-border-left--1;
  }

  tbody tr:nth-child(odd) td {
    @extend .ds-u-fill--gray-lightest;
  }
}

.OriginalMedicareStarRatingsHelpDrawerDialog {
  @media screen and (min-width: $media-width-md) {
    max-width: $media-width-lg;
    width: 75vw;
  }
}
