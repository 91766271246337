.mct-c-summary-page-drug-cards-container {
  width: 100%;
  &__list {
    display: flex;
    flex-direction: column;
    margin-block: 0;
    padding-inline: 0;
    li {
      list-style: none;
    }
  }

  &__action-buttons {
    margin: $spacer-3 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: $spacer-3;

    @media screen and (min-width: $media-width-md) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__add-pde-button {
    @extend .mct-hide-print;
  }
}
