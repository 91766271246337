.mct-c-out-of-network-pharmacy-label {
  height: $spacer-3;
  font-weight: 500;
  &:not(&--narrow) {
    display: inline-flex;
  }
  &-body {
    @extend .ds-u-radius--pill;
    display: flex;
    align-items: center;
    &__icon {
      color: $color-warn-darker;
      &--dark-background {
        color: rgba($color-white, 0.75);
      }
    }
    &__text {
      color: $color-warn-darker-contrast;
      padding: 0 12px 0 $spacer-1;
      &--wide {
        padding: 0 17px 0 14px;
      }
      &--dark-background {
        color: rgba($color-white, 0.75);
      }
    }
  }
}
