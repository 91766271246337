.LoadingMask {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  @extend .ds-u-color--white;
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-justify-content--center;
  @extend .ds-u-align-items--center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000 !important;

  &__cube1,
  &__cube2,
  &__cube3,
  &__cube4 {
    background-color: $color-primary;

    &::before {
      background-color: $color-primary-light !important;
    }
  }

  h1 {
    @extend .ds-u-color--white;
  }
}
