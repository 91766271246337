.Footnotes {
  @extend .ds-u-margin--0;

  .Footnote {
    &__dd {
      align-items: flex-start;

      &:not(:last-child) {
        @extend .ds-u-margin-bottom--1;
      }
    }

    &__marker {
      white-space: nowrap;
    }
  }
}

.Footnote {
  &__dt {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    margin: 0;
    clip: rect(1px, 1px, 1px, 1px);
  }

  &__dd {
    margin-inline-start: 0;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--row-reverse;
    @extend .ds-u-justify-content--end;

    &:focus {
      p {
        @extend .ds-u-fill--secondary-lightest;
      }
    }
  }

  &__marker {
    @extend .ds-u-text-decoration--none;
    @extend .ds-u-padding--0;
    @extend .ds-u-color--secondary-light;
    min-width: fit-content;

    &--linked {
      &:hover {
        @extend .ds-u-text-decoration--underline;
      }
    }

    sup {
      &::before {
        content: "[";
        vertical-align: text-top;
        font-size: 0.7rem;
        padding-right: 0.1rem;
        display: inline-block;
      }

      &::after {
        content: "]";
        vertical-align: text-top;
        font-size: 0.7rem;
        padding-left: 0.1rem;
        display: inline-block;
      }
    }
  }
}
