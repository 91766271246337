#TestingLabsAndImagingTable {
  .diagnostic_tests_and_procedures__heading {
    & > span {
      :first-child {
        @extend .ds-u-text-align--left;
        @extend .mct-c-table__header-title;
        color: $color-base;
      }
    }
  }
}
