.PacePlansPage {
  @media screen and (max-width: $media-width-md) {
    .StateSelector {
      order: 2;
    }

    .PrintButton {
      margin-left: -24px;
    }
  }

  &__banner {
    @extend %mct-l-flush-to-edge;
    column-gap: var(--spacer-2);
  }

  &__state-selector {
    flex-grow: 1;
    order: 1;
    @media screen and (min-width: $media-width-sm) {
      order: 0;
    }
  }

  &__print-button {
    align-self: flex-start;
    @media screen and (min-width: $media-width-sm) {
      align-self: auto;
    }
  }

  &__h1 {
    @extend .ds-u-margin--0;
    @extend .ds-u-font-size--base;
    @extend .ds-text-body--md;
    @extend .ds-u-font-weight--semibold;
    font-family: $font-family-rubik;
  }

  &__content {
    @extend %mct-l-container--pace-pap-spap;
  }
}

.PacePlanCard {
  @extend .ds-u-padding--1;
  @extend .ds-u-lg-padding--4;

  &__programName {
    @extend .ds-u-font-weight--normal;
    font-family: $font-family-montserrat;
  }

  &__sectionHeading {
    @extend .ds-u-text-transform--uppercase;
    font-family: $font-family-rubik;
    font-size: $font-size-md;
    font-weight: 400;
    margin-bottom: $spacer-1;
  }

  &__officeHoursHeading {
    @extend .ds-u-display--none;
    @extend .ds-u-md-display--block;
  }

  &__cardColumn {
    @extend .ds-l-col--12;
    @extend .ds-l-md-col--5;
  }

  @media print {
    box-shadow: none;
    border: none;
    padding-left: 1em !important;
    padding-top: 1em !important;
    page-break-inside: avoid;

    &__mobileOfficeHoursBox {
      height: auto !important;
    }

    &__officeHoursHeading {
      display: block !important;
    }

    &__mobileOfficeHoursBox_trigger {
      display: none;
    }

    &__cardColumn {
      display: inline-block !important;
      width: 50% !important;
      vertical-align: top;
    }

    .ds-l-row {
      display: block !important;
    }

    .ds-l-col--1 {
      display: none;
    }
  }

  &__mobileOfficeHoursBox {
    @media screen and (min-width: $media-width-md) {
      height: auto !important;
    }
  }

  &__mobileOfficeHoursBox_trigger {
    @extend .ds-u-padding--2;
    @extend .ds-u-margin-bottom--2;
    background-color: $color-primary-lightest !important;
    width: 100%;

    @media screen and (min-width: $media-width-md) {
      display: none;
    }
  }

  .ds-l-col--1 {
    @media screen and (max-width: $media-width-md) {
      display: none;
    }
  }
}
