.PharmacyCard {
  @extend .ds-u-border--2;
  @extend .ds-u-margin-bottom--2;
  border-color: #d6d7d9;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0 #d6d7d9;
  list-style-type: none;
  overflow: hidden;
  position: relative;
  width: 49%;

  &:hover,
  &:focus-within {
    box-shadow:
      2px 2px 3px 0 #d6d7d9,
      4px 4px 0 0 $color-secondary-light inset,
      -4px -4px 0 0 $color-secondary-light inset;
  }

  @media screen and (max-width: $media-width-sm) {
    width: 100%;
  }

  &__button {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-padding--0;
    @extend .ds-u-color--base-inverse;
    @extend .ds-u-border--0;
    background-color: transparent;
    cursor: pointer;
    line-height: 1.5;
    outline: 0;
    text-align: left;
    width: 100%;

    &:hover,
    &:focus {
      box-shadow: 0 2px 0 0 $color-secondary-light inset;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }

  &__content {
    @extend .ds-u-margin-x--2;
    color: $color-gray-dark;
    text-transform: capitalize;

    &__name {
      @extend .ds-text-heading--xl;
      @extend .ds-u-margin-bottom--1;
    }

    &__address {
      @extend .ds-u-margin-bottom--3;
    }
  }

  &__checkBox {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--center;
    @extend .ds-u-align-items--center;
    @extend .ds-u-margin-right--2;
    @extend .ds-u-border--2;
    border-color: $color-black !important;
    width: 32px;
    height: 32px;
  }

  &__skip-link {
    left: -9999px;
    outline: 0;
    padding: 0.5rem 1rem;
    position: absolute;
    text-align: right;

    &:focus {
      animation-duration: 0.75s;
      animation-name: BOUNCE_SKIP_LINK;
      animation-iteration-count: 1;
      background-color: $color-primary-lightest;
      border-top: 2px solid $color-gray-lighter;
      bottom: 4px;
      left: 4px;
      right: 4px;
    }

    @keyframes BOUNCE_SKIP_LINK {
      50% {
        transform: translate(0, -5px);
      }
    }
  }

  &--selected {
    @extend .ds-u-color--base;
    color: $color-white !important;
    border-color: $color-white !important;
    background: $color-primary-light;

    &:hover,
    &:focus-within {
      box-shadow:
        2px 2px 3px 0 #d6d7d9,
        4px 4px 0 0 $color-primary-dark inset,
        -4px -4px 0 0 $color-primary-dark inset;
    }

    div {
      color: $color-white;
      box-shadow: none !important;
    }

    i {
      font-size: 18px;
    }

    button {
      outline: 0;

      &:hover,
      &:focus {
        box-shadow: 0 2px 0 0 $color-primary-dark inset;
      }

      &::-moz-focus-inner {
        border: 0;
      }
    }
  }

  &--disabled {
    button {
      cursor: not-allowed;
    }

    &:hover,
    &:focus-within {
      cursor: not-allowed;
    }
  }
}
