.mct-c-coverage-selector {
  &__coverage-years {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;

    div:first-child {
      flex-basis: calc(30% - (#{$spacer-3}/ 2));
    }

    div:nth-child(2) {
      flex-basis: calc(70% - (#{$spacer-3}/ 2));
    }
  }

  &__zipcode-plan-type {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $media-width-sm) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__zipcode {
    [name="zipcode"] {
      max-width: none !important;
      border-color: $black-200;
    }

    @media screen and (min-width: $media-width-sm) {
      flex-basis: calc(30% - (#{$spacer-3}/ 2));

      [name="zipcode"] {
        line-height: 1.5 !important;
      }
    }
  }

  &__plantype {
    padding-top: $spacer-2;

    .CCXPBase__button {
      @extend .ds-u-padding-y--2;
      @extend .ds-u-margin-y--05;
      width: 100%;
      justify-content: space-between !important;
      border-color: $black-200 !important;

      &--truncate {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
      }

      &--error {
        border-color: $color-error !important;
      }
    }

    @media screen and (min-width: $media-width-sm) {
      padding-top: 0;
      flex-basis: calc(70% - (#{$spacer-3}/ 2));
    }
  }

  &__zip-counties {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-wrap--wrap;
    @extend .ds-u-align-items--center;

    div {
      margin-right: $spacer-3;
    }
  }

  &__alert {
    .ds-c-alert__heading {
      @extend .ds-text-heading--md;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  &__text-field--error {
    & input {
      @extend .ds-c-field--error;
      border-color: $color-error !important;
    }
  }

  &__start-button {
    width: 100%;
    @media screen and (min-width: $media-width-lg) {
      width: auto;
    }
  }
}
