.PlanComparePage {
  &__enrollDialog {
    order: unset;
    width: fit-content;
    @extend .ds-u-margin-top--2;

    p {
      @extend .ds-u-margin--0;
    }

    .mct-c-enroll-button-form__text {
      @extend .ds-u-text-align--center;
      @extend .ds-u-lg-text-align--left;
    }
  }

  &__currentPlanBanner {
    background-color: $color-primary-lightest;
    @extend .ds-u-font-weight--bold;
    @extend .ds-u-padding--1;
    @extend .ds-u-text-align--center;
  }

  &__planHeader {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-justify-content--between;
    height: 100%;
    position: relative;
  }

  &__floatingHeader {
    @extend .ds-u-fill--white;
    @extend .ds-u-padding--0;
    @extend .ds-u-padding-top--2;
    vertical-align: top;
    &:not(:first-child) {
      @extend .ds-u-border-left--1;
    }

    @extend .ds-u-border-bottom--1;

    @media screen and (min-width: $media-width-lg) {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    @media print {
      border-bottom: 0 !important;
    }
  }

  &__planHeaderPlanName {
    @extend .ds-u-margin-right--3;
    @media print {
      line-height: $font-size-xl;
    }
  }

  &__close {
    margin-top: -8px;
    position: absolute;
    top: 8px;
    right: 8px;
    align-self: flex-start;

    &:hover {
      color: $color-secondary !important;
    }
  }

  &__planDetailsBtn {
    align-self: flex-start;
    @extend .ds-u-margin-top--2;
  }

  &__coachmark {
    max-width: none;
    margin: $spacer-3 0;
    &.mct-c-alert-message {
      .mct-c-alert-message__body {
        max-width: 125ex;
        p:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  &__table {
    position: relative;
    border-spacing: 0;
    border-collapse: separate;
    min-width: 100%;
    width: 100%;
    table-layout: fixed;

    thead {
      @extend .ds-u-display--none;

      @media screen and (min-width: $media-width-lg), print {
        display: table-header-group !important;
      }

      td {
        &:not(.PlanComparePage__floatingHeader) {
          @extend .ds-u-padding-x--3;
        }
      }
    }

    tbody {
      td:not(.PlanComparePage__tableHeader) {
        vertical-align: top;

        &:not(:first-child):not(.PlanComparePage__selectedCell) {
          @extend .ds-u-border-left--1;
        }

        @extend .ds-u-padding--3;

        @media print {
          padding: 8px !important;
        }

        @media screen and (max-width: 1023px) {
          &:not(
              .PlanComparePage__selectedCell,
              .PlanComparePage__tableHeader,
              .PlanComparePage__tableSubHeader
            ) {
            display: none !important;
          }
        }
      }
      tr {
        &:not(:last-of-type) {
          td:not(.PlanComparePage__tableHeader) {
            @extend .ds-u-border-bottom--1;
          }
        }
        + tr {
          .PlanComparePage__tableHeader {
            @extend .ds-u-border-top--0;
          }
        }
      }
    }
  }

  &__tableHeader {
    @extend .ds-u-border--1;

    @extend .ds-u-fill--gray-lightest;
    @extend .ds-text-heading--2xl;
    @extend .ds-u-padding-y--2;
    @extend .ds-u-padding-x--3;

    @media print {
      background-color: $color-white !important;
      border: none !important;
      padding: 8px !important;
    }
  }

  &__tableSubHeader {
    @extend .ds-u-border--1;
    @extend .ds-u-fill--secondary-lightest;
    @extend .ds-u-text-transform--uppercase;
    @extend .ds-u-font-weight--semibold;
    font-family: $font-family-montserrat;
  }

  &__tableSubHeaderDescription {
    @extend .ds-u-font-weight--normal;
    @extend .ds-u-margin-bottom--0;
    font-family: $font-family-rubik;
    text-transform: none;
  }

  /* this is the style for the column label */
  &__tableColumnLabel {
    @extend .ds-text-heading--md;
  }

  /* this is the style for the first column in the table */
  &__tableLabelColumn {
    @extend .ds-u-display--none;

    @media screen and (min-width: $media-width-lg), print {
      display: table-cell !important;
    }
  }

  &__countyName {
    &--tablet {
      @extend .ds-u-font-size--lg;
      @extend .ds-u-font-weight--bold;
      @extend .ds-u-margin--0;
      font-family: $font-family-montserrat !important;
    }

    &--mobileDesktop {
      @extend .ds-u-margin--0;
      @extend .ds-u-lg-margin-bottom--6;
      @extend .ds-u-font-weight--bold;
      font-family: $font-family-montserrat !important;
      margin-top: -#{$spacer-2} !important;
      @media screen and (min-width: $media-width-lg) {
        font-size: 24px;
        margin-top: #{$spacer-6} !important;
      }
    }
  }

  &__planRadioInput {
    @extend .ds-u-display--flex;

    &:not(:last-child) {
      @extend .ds-u-margin-bottom--2;
    }

    input[type="radio"] {
      appearance: none;
      @extend .ds-u-margin--0;
      @extend .ds-u-padding--0;

      &:checked + label {
        @extend .ds-u-fill--primary;
        @extend .ds-u-color--white;
      }

      &:focus + label {
        box-shadow:
          0 0 0 4px rgba(241, 173, 21, 0.4),
          0 0 0 2px #f1ad15;
        border-color: #f1ad15;
        outline: none;
      }
    }

    label {
      @extend .ds-u-border--1;
      @extend .ds-u-display--block;
      @extend .ds-u-padding--2;
      @extend .ds-u-text-align--center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
