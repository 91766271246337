.ds-c-alert {
  &:not(.ds-c-alert--warn):not(.ds-c-alert--success):not(.ds-c-alert--error) {
    background-color: $color-secondary-lightest;
    border-color: $color-secondary-light;
    color: #212121;
  }

  &__heading {
    @extend .ds-u-leading--base;
  }

  .previewBanner & {
    @media screen and (max-width: $media-width-sm) {
      background-position: 8px 8px;
      background-size: 32px;
      font-size: 16px;
      line-height: 1.25;
      padding: 8px;

      .ds-c-alert__body {
        padding-left: 40px;
      }
    }
  }
}
