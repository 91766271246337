.MedigapPolicyCard {
  @extend .ds-u-padding--1;
  @extend .ds-u-lg-padding--4;
  @extend .ds-u-margin-bottom--4;
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-wrap--wrap;
  @extend .ds-u-justify-content--between;

  @media print {
    box-shadow: none;
    border-radius: 0 !important;
    border-bottom: 1px solid $color-gray-lighter;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    padding: 0 !important;
    margin-top: 1em !important;
    margin-bottom: 0 !important;
    page-break-inside: avoid;

    &__divider {
      display: none !important;
    }

    &__monthly_cost,
    &__contact_info,
    &__pricing_info {
      display: inline-block;
      width: 30%;
      vertical-align: text-top;

      p {
        width: 100%;
      }
    }
  }

  &__header {
    @extend .ds-u-padding-bottom--4;
  }

  &__body {
    @extend .ds-l-row;
    width: 100%;
  }

  &__section_heading {
    @extend .ds-u-text-transform--uppercase;
    font-family: $font-family-rubik;
    font-size: 16px;
    font-weight: 500 !important;
  }

  &__section_column {
    @extend .ds-l-col--12;
    @extend .ds-u-margin-bottom--3;
    @extend .ds-u-lg-margin-bottom--0;

    &--left {
      @extend .ds-l-lg-col--4;
    }
    &--middle {
      @extend .ds-l-lg-col--3;
    }
    &--right {
      @extend .ds-l-lg-col--3;
    }
  }

  &__company_field {
    @extend .ds-u-margin-top--3;
  }

  &__divider {
    @extend .ds-l-col--1;
    @extend .ds-u-display--none;
    @extend .ds-u-lg-display--block;

    &:after {
      @extend .ds-u-display--block;
      @extend .ds-u-fill--gray-lighter;
      content: "";
      height: 100%;
      left: 50%;
      position: relative;
      width: 1px;
    }
  }
}
