.StickyFooter {
  position: sticky;
  bottom: 0px;
  z-index: 2;

  &.js-is-stuck {
    position: relative !important;
    left: 0;
    right: 0;
  }

  &.js-is-sticky {
    left: 56px;
    right: 56px;
    width: auto;
  }

  @media screen and (max-width: $media-width-md) {
    margin-left: -55px;
    margin-right: -56px;
  }

  @media screen and (max-width: $media-width-sm) {
    margin-left: -16px;
    margin-right: -24px;
  }
}

.FooterCard {
  @extend .ds-u-padding--1;
  @extend .ds-u-margin-left--1;
  @extend .ds-u-margin-right--2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  background-color: $color-primary-lightest;
  position: relative;

  @media screen and (max-width: $media-width-sm) {
    justify-content: left;
  }

  &__name {
    @extend .ds-u-font-size--sm;
    @extend .ds-u-sm-margin-right--1;
    @extend .ds-u-margin-right--2;
    color: $color-secondary;
    vertical-align: middle;
  }
}

.Footer {
  @extend .ds-u-padding--2;
  @extend .ds-u-display--flex;
  @extend .ds-u-justify-content--between;
  max-height: 500px;
  transition: max-height 0.5s ease-in-out;

  @media screen and (max-width: $media-width-md) {
    flex-direction: column;
  }

  &.closed {
    max-height: 500px;

    @media screen and (max-width: $media-width-md) {
      max-height: 35px;
      overflow: hidden;
    }
  }

  &__header {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-margin-left--1;
    @extend .ds-u-align-items--center;

    h2 {
      @media screen and (max-width: $media-width-md) {
        margin: 0 !important;
      }
    }
  }

  &__list {
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--start;

    @media screen and (max-width: $media-width-md) {
      flex-direction: column;
      margin-top: 8px;

      & > * {
        margin-top: 8px !important;
        margin-bottom: 16px !important;
      }
    }
  }

  &__title {
    @extend .ds-u-font-size--lg;
    align-self: center;
    color: $color-white;
    outline: 0;

    &:focus {
      outline: 3px solid $color-primary-light;
      outline-offset: 2px;
      padding: 0;
    }
  }

  &__infoCard {
    @extend .FooterCard;
    color: $color-white;
    background-color: $color-secondary-light;
    border: none;
  }

  &__button {
    align-self: center;

    @media screen and (max-width: $media-width-md) {
      height: unset !important;
    }
  }
}

.FooterToggle {
  @extend .ds-c-button;
  @extend .ds-c-button--small;
  @extend .ds-u-margin-right--2;
  @extend .ds-u-fill--gray-lightest;
  @extend .ds-u-color--gray;
  border-color: $color-gray;
  display: none;
  font-size: 16px;

  i {
    padding: 5px;
  }

  @media screen and (max-width: $media-width-md) {
    display: block;
  }
}

.FooterAction {
  align-self: center;

  @media screen and (max-width: $media-width-md) {
    align-self: normal;
    background-color: white;
    margin: -16px;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    margin-bottom: -16px !important;
  }
}

.FooterToastContainer {
  position: relative;
  & > .Toastify__toast > .Toastify__close-button {
    display: none;
  }
}
