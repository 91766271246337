.mct-c-summary-page-drug-card {
  display: flex;
  margin-bottom: $spacer-3;
  // width of two LIS summary page cards minus padding
  max-width: calc(1204px - (2 * $spacer-3));
  // specified in design documents
  min-height: calc(144px - (2 * $spacer-3));
  padding: $spacer-3;
  @media screen and (max-width: $media-width-md) {
    flex-direction: column;
  }

  @media print {
    border: none;
    box-shadow: none;
    margin: $spacer-3 0;
    padding: 0;
  }

  &-features {
    display: flex;
    gap: 0;
    width: calc(100% * (2 / 3));

    @media screen and (max-width: $media-width-md) {
      flex-direction: column;
      gap: $spacer-4;
    }

    &__column {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      @media screen and (max-width: $media-width-md) {
        width: auto;
      }
      &--placeholder {
        display: block;
        @media screen and (max-width: $media-width-md) {
          display: none;
        }
      }
      &--quantity {
        margin: 0 auto;
        width: 20%;
        @media screen and (max-width: $media-width-md) {
          width: auto;
          margin: 0;
        }
      }
    }

    &__headings {
      font-size: $font-size-md;
      margin-bottom: $spacer-1;
      margin: 0;
      @media screen and (max-width: $media-width-md) {
        font-size: $font-size-lg;
      }
    }

    &__values {
      color: $color-gray-light;
      margin: 0;
    }
  }
}

.mct-c-summary-page-drug-card-header {
  @extend .ds-text-heading--lg;
  margin-bottom: 0;
  width: 25%;
  margin-right: auto;

  @media screen and (max-width: $media-width-md) {
    margin-bottom: $spacer-3;
    width: auto;
  }
  &__drug-name {
    word-break: break-word;
    margin-bottom: 0;
  }
  &__is-generic {
    @extend .ds-text-heading--md;
    @extend .ds-u-font-weight--normal;
    margin: 0;
    color: $color-gray-light;
  }
}
