.planCompareCard {
  @extend .m-c-card;
  @extend .ds-u-padding--3;
  @extend .ds-u-padding-top--4;
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-justify-content--between;
  box-sizing: border-box;
  min-height: 11em;
  height: 100%;
  position: relative;

  p {
    @extend .ds-u-margin--0;
    @extend .ds-u-padding--0;
  }

  &__closeButton {
    position: absolute;
    top: 8px;
    right: 8px;

    &:hover {
      color: $color-secondary !important;
    }

    @media screen and (max-width: $media-width-lg) {
      display: none !important;
    }
  }

  &__deductibleSection {
    @extend .ds-u-margin-top--2;

    &__subSection {
      @extend .ds-u-margin-bottom--2;
    }

    &__label:last-child {
      @extend .ds-u-margin-bottom--0;
    }

    @media screen and (max-width: $media-width-lg) {
      display: none !important;
    }
  }

  &__detailsSection {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-justify-content--between;
  }

  &__detailsHeader {
    @extend .ds-text-heading--lg;
    padding-right: 24px;

    @media screen and (max-width: $media-width-lg) {
      display: none !important;
    }
  }

  &__premiumSection {
    @extend .ds-u-margin-top--2;
  }

  &__premiumSection__label,
  &__deductibleSection__label {
    @extend .ds-u-margin-bottom--2;
    @extend .ds-text-heading--lg;
    font-weight: 400 !important;

    &:last-of-type {
      @extend .ds-u-margin-bottom--0;
    }
  }

  &__actionSection {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-align-items--center;

    button {
      @extend .ds-u-margin-top--1;
      width: auto;

      @media screen and (min-width: $media-width-md) {
        width: 176px;
      }

      @media screen and (min-width: $media-width-lg + 1) {
        width: 100%;
      }
    }

    & > .EnrollTransitionDialog {
      @extend .ds-u-padding--0;
      width: 100%;
    }

    @media screen and (max-width: $media-width-lg) {
      display: none !important;
    }
  }

  &.currentPlan {
    @extend .ds-u-fill--gray-lightest;

    .planCompareCard__premiumSection {
      background-color: $color-primary-lightest !important;
    }
  }
}
