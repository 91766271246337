// Create some responsive border utilities
@each $breakpoint in map-keys($breakpoints) {
  $sides: top, bottom, left, right;
  $value: map-get($breakpoints, $breakpoint);
  @for $i from 1 through 2 {
    // FORMAT: .mct-u-[breakpoint]-border-[x|y|bottom|left|right|top]--[SIZE]
    .mct-u-#{$breakpoint}-border--#{$i} {
      border-width: 0 !important;
      @media (min-width: $value) {
        border-width: #{$i}px !important;
      }
    }
    @each $side in $sides {
      .mct-u-#{$breakpoint}-border-#{$side}--#{$i} {
        border-#{$side}-width: 0 !important;
        @media (min-width: $value) {
          border-#{$side}-width: #{$i}px !important;
        }
      }
    }
    .mct-u-#{$breakpoint}-border-x--#{$i} {
      border-inline-width: 0 !important;
      @media (min-width: $value) {
        border-inline-width: #{$i}px !important;
      }
    }
    .mct-u-#{$breakpoint}-border-y--#{$i} {
      border-block-width: 0 !important;
      @media (min-width: $value) {
        border-block-width: #{$i}px !important;
      }
    }
  }
}

// Custom border colors

.mct-u-border--gray-dark {
  border-color: $color-gray-dark !important;
}

.mct-u-border--gray-lightest {
  border-color: $color-gray-lightest !important;
}
