.PhraseAppToolbar {
  @extend .ds-u-padding-y--2;
  @extend .ds-u-padding-x--7;
  @extend .ds-u-fill--primary;
  @extend .ds-u-display--flex;
  @extend .ds-u-justify-content--end;
  z-index: 9995;
  position: sticky;
  top: 0;
}
