// Overrides of or additions to the MGov DS (which selectively overrides the CMSDS)
// @see https://github.com/CMSgov/design-system/pull/1229

.ds-c-field {
  &--error {
    border-width: 3px;
  }
}

.ds-c-label {
  $l: &;
  // Require check for a modifier that would display labels inline (not default)
  &:not(#{$l}--inline) {
    + .ds-c-field,
    > .ds-c-field {
      &--error {
        margin-top: 0; // Override of added top margin to keep label in original alignment
      }
    }
  }
}
