h1 {
  @extend .ds-text-heading--3xl;
  // Usually you shouldn't remove the focus outline from elements
  // but we've added focus to the h1 of every page on page render,
  // and since it's not an interactive element it's ok.
  &:focus {
    outline: 0;
  }
}

h2 {
  @extend .ds-text-heading--2xl;
}

h3 {
  @extend .ds-text-heading--xl;
}

h4 {
  @extend .ds-text-heading--lg;
}

h5 {
  @extend .ds-text-heading--md;
}

h6 {
  @extend .ds-text-heading--sm;
}

strong {
  @extend .ds-u-font-weight--bold;
}
