.mct-c-in-network-pharmacy-label {
  height: $spacer-3;
  font-weight: 500;
  &:not(&--narrow) {
    display: inline-flex;
  }
  &-body {
    @extend .ds-u-radius--pill;
    display: flex;
    align-items: center;
    &--preferred {
      background-color: $color-success-lightest;
      &-dark-background {
        background-color: rgba($color-white, 0.25);
      }
    }
    &__icon {
      color: $color-success;
      font-size: 17.25px;
      position: relative;
      left: -1px;
      &--dark-background {
        color: $color-white;
      }
    }
    &__text {
      color: $color-success;
      padding: 0 12px 0 7px;
      &--preferred {
        color: $color-success-dark;
      }
      &--wide {
        padding: 0 17px 0 15px;
      }
      &--dark-background {
        color: $color-white;
      }
    }
  }
  &__extra-description {
    color: $color-success;
    margin: 0 $spacer-half 0 $spacer-1;
    &--wide {
      margin: 0 17px 0 13px;
    }
    &--narrow {
      display: none;
    }
    &--dark-background {
      color: $color-white;
    }
  }
}
