.mct-c-ntm-page-resource-card {
  @extend .ds-u-padding--3;
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-flex-wrap--nowrap;
  @extend .ds-u-justify-content--between;
  @extend .ds-u-align-items--stretch;

  gap: $spacer-3;

  &__button {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--center;
    @extend .ds-u-justify-content--center;
    min-height: 40px;
  }
}
