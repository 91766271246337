.PlanDetailsPagePlanContact {
  @extend .ds-u-md-display--flex;
  @extend .ds-u-margin-top--1;
  @extend .ds-u-margin-bottom--3;
  @extend .ds-u-justify-content--between;

  &__link {
    @extend .ds-u-padding--0;
    white-space: nowrap;
    font-family: var(--font-sans);
  }

  &__telephone {
    color: $color-gray-light !important;
  }

  &__leftCol {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--row;
    @extend .ds-u-md-align-items--end;
    @extend .ds-u-padding-bottom--1;

    @media screen and (max-width: $media-width-sm) {
      flex-direction: column !important;
    }
  }

  &__leftColCell {
    @extend .ds-text-heading--md;
    @extend .ds-u-margin--0;
    color: $color-gray-light;

    &-label {
      font-family: $font-family-rubik;
    }

    &:not(:last-child) {
      @extend .ds-u-border-right--2;
      @extend .ds-u-padding-right--1;
    }

    &:not(:first-child) {
      @extend .ds-u-padding-left--1;
    }

    @media screen and (max-width: $media-width-sm) {
      &:first-child {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
      }
      &:not(:last-child) {
        border: 0 !important;
        padding-right: 0 !important;
      }

      &:not(:first-child) {
        padding-left: 0 !important;
      }

      padding-bottom: $spacer-1 !important;
    }
  }

  &__rightCol {
    @extend .ds-u-md-display--flex;
    @extend .ds-u-md-justify-content--end;
    @extend .ds-u-md-align-items--end;
    @extend .ds-u-margin--0;
    @extend .ds-u-padding-bottom--1;
  }

  &__enrollButton {
    @extend .ds-u-padding-y--2;
    @extend .ds-u-padding-x--4;
    @media screen and (max-width: $media-width-sm) {
      width: 100% !important;
    }
  }
}
