.mct-c-actionsbar {
  $b: &;
  @extend .ds-l-row;
  @extend .ds-u-align-items--center;
  @extend .ds-u-justify-content--between;
  @extend .ds-u-margin-top--3;
  @extend .ds-u-margin-bottom--2;
  @extend .mct-hide-print;
  &__back {
    @extend .mct-hide-print;
    // Allow back link to take up more grid width if there's no extra content
    #{$b}--no-extra-content & {
      @extend .ds-l-md-col--8;
    }
  }
  &__link {
    @extend .ds-u-align-items--center;
    @extend .ds-u-display--flex;
    max-width: max-content;
  }
  &__print {
    @extend .ds-l-col--auto;
    @extend .ds-l-md-col--4;
    @extend .ds-u-justify-content--end;
    @extend .ds-u-display--flex;
    @extend .mct-hide-print;
    .PrintButton {
      @extend .ds-u-padding--0;
    }
  }
  &__back,
  &__content {
    @extend .ds-l-col;
    @extend .ds-l-md-col--4;
  }
  &__content {
    @extend .ds-u-justify-content--center;
  }
}
