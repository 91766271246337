.mct-c-dropdown-with-button {
  $component: &;
  gap: var(--spacer-2);
  display: grid;
  grid-template-areas:
    "."
    "dropdown-container"
    "dropdown-container"
    "button";
  &__dropdown-container {
    // ignore this container in the grid layout, applying to its direct children
    display: grid;
    grid-area: dropdown-container;
    grid-row-start: 1;
    grid-template-areas:
      "label"
      "dropdown"
      "dropdown-list";
    grid-template-rows: subgrid;
    .ds-c-dropdown__menu-container {
      grid-area: dropdown-list;
      margin-top: calc(-1 * var(--spacer-2));
    }
    max-width: 100%;
  }
  &__dropdown {
    grid-area: dropdown;
    margin-top: 0;
    max-width: 100%;
  }
  &__label {
    grid-area: label;
    // Negate the full row gap between the label and dropdown, but keep a half space between them
    margin-bottom: calc(-0.75 * var(--spacer-2));
  }
  &__button-container {
    align-content: center;
    // flex properties make the button fill its container and stay vertically centered
    display: flex;
    flex-direction: column;
    grid-area: button;
    justify-content: center;
    @media screen and (min-width: $media-width-sm) {
      width: fit-content;
    }
  }
  &__button {
    margin-top: calc(-1 * var(--spacer-2));
    @media screen and (min-width: $media-width-sm) {
      max-width: fit-content;
      margin-top: 0;
    }
  }

  @media screen and (min-width: $media-width-sm) {
    grid-template-areas:
      ". ."
      "dropdown-container button"
      "dropdown-container .";
    // For above mobile viewport, the dropdown can expand as needed, but keep the button at
    // its intrinsic width (see grid-template-areas for above mobile)
    // grid-template-columns: minmax(max-content var(--form__max-width)) auto;
    grid-template-columns: 1fr auto;
    &#{$component}--constrain-dropdown-width {
      grid-template-columns: minmax(min-content, var(--form__max-width)) auto;
    }
  }
}
