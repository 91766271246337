.LocationEditor {
  @extend .ds-u-align-items--center;

  @media screen and (max-width: $media-width-lg) {
    &:after {
      content: "";
      display: block;
      background: $color-gray-lighter;
      height: 4px;
      margin-top: 16px;
      width: 100%;
    }
  }

  &__toggleButton {
    @extend .ds-u-text-decoration--underline;
    @extend .ds-u-padding--0;
  }

  .CountyPicker__autocomplete {
    @extend .ds-u-margin-top--0;
    @extend .ds-u-lg-margin-right--2;
  }
}
