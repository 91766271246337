.mct-c-csr-landing {
  &__container {
    @extend .ds-u-border--1;
    @extend .ds-u-padding--2;
  }

  &__info-item-container {
    display: grid !important;
    column-gap: $spacer-2;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  }

  &__info-item {
    @extend .ds-u-margin-y--2;

    &--border-bottom {
      @extend .ds-u-padding-bottom--2;
      @extend .ds-u-border-bottom--1;
    }

    .ds-text-body--md {
      @extend .ds-u-margin-top--0;
    }
  }
}
