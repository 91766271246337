.mct-c-insulin-drug-coachmark {
  padding-bottom: $spacer-1 !important;
  margin-bottom: $spacer-3;
  .mct-c-alert-message__combined-title {
    @extend .ds-u-font-weight--bold;
  }
  li {
    list-style-type: disc;
  }
}
