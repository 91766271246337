// Placeholders
%PlanCard__info_group--base {
  @extend .ds-u-display--flex;
  @extend .ds-u-align-items--baseline;
  @extend .ds-u-flex-wrap--wrap;
  @extend .ds-u-margin-bottom--1;
}

.PlanCard {
  @extend .ds-u-md-padding-top--3;
  @extend .ds-u-md-padding-bottom--4;
  @extend .ds-u-md-padding-x--3;
  @extend .ds-u-padding--2;
  @extend .ds-u-margin-bottom--2;
  font-size: 18px;
  position: relative;

  .Tooltip__trigger {
    font-size: 20px;
  }

  .Tooltip__body {
    font-size: 16px;
  }

  .StarRating__message {
    font-size: 18px !important;
  }

  @media screen and (min-width: 1025px) {
    font-size: 20px;
  }

  &__header {
    @extend .ds-u-margin-bottom--0;
    font-size: 24px;

    @media screen and (min-width: $media-width-sm) {
      font-size: 28px;
    }
  }

  &__current_plan_banner {
    @extend .ds-u-padding-x--2;
    @extend .ds-u-sm-padding-x--4;
    @extend .ds-u-padding-y--1;
    @extend .ds-u-font-weight--semibold;
    background-color: $color-primary-lightest;
    font-family: $font-family-montserrat;
    margin: -8px -32px 16px;

    @media screen and (max-width: $media-width-sm) {
      margin: 0 -16px 8px;
    }
  }

  &__sub_header {
    @extend .ds-u-color--gray-light;
    @extend .ds-u-font-size--lg;
    font-family: $font-family-montserrat;
  }

  &__body {
    position: relative;

    & > div {
      @extend .ds-u-display--flex;
      @extend .ds-u-flex-wrap--wrap;
      @media screen and (min-width: 1025px) {
        flex-wrap: nowrap !important;
      }
    }
  }

  &__flex_left {
    @extend .ds-u-md-margin-right--7;
    @extend .ds-u-sm-margin-right--0;
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-direction--column;
    @extend .ds-u-justify-content--between;
    flex: 60%;
  }

  &__flex_right {
    flex: 30%;
    font-size: 18px;

    @media screen and (max-width: 1025px) {
      flex: 100%;
    }
  }

  &__section_heading {
    @extend .ds-u-text-transform--uppercase;
    @extend .ds-u-border-bottom--2;
    @extend .ds-u-font-size--lg;
    @extend .ds-u-margin-bottom--1;
    &--no_uppercase {
      text-transform: none !important;
    }
  }

  &__year-context {
    @extend .ds-u-text-transform--lowercase;
  }

  &__section {
    @extend .ds-u-margin-bottom--3;
  }

  &__info_group {
    @extend %PlanCard__info_group--base;

    > *:first-child {
      @extend .ds-u-margin-right--1;
      flex: 100%;

      @media screen and (min-width: $media-width-sm) {
        flex: none;
      }

      @media print {
        flex: none;
      }
    }

    p {
      @extend .ds-u-margin--0;
    }

    &__otherCosts {
      @extend %PlanCard__info_group--base;

      > *:first-child {
        @extend .ds-u-margin-right--1;
        @media screen and (max-width: $media-width-sm) {
          flex: 100%;
        }
      }
    }
  }

  &__line_items {
    > *:not(:last-child) {
      @extend .ds-u-margin-top--0;
      @extend .ds-u-margin-bottom--1;
    }
  }

  &__more_info {
    @media screen and (min-width: 1025px) {
      height: auto !important;
    }
  }

  &__more_info_trigger {
    @extend .ds-c-button;
    @extend .ds-c-button--ghost;
    @extend .ds-u-padding-y--1;
    @extend .ds-u-padding-right--1;
    @extend .ds-u-margin-y--1;
    @extend .ds-u-color--secondary-light;

    &:hover {
      @extend .ds-u-color--secondary;
    }

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  &.PLAN_TYPE_PDP {
    .PlanCard__actions {
      position: relative;
      bottom: auto;
    }
  }

  &__actions {
    @extend .ds-u-margin-y--1;
    align-items: center;
    display: grid;
    gap: $spacer-2 $spacer-4;
    grid-template-columns: 1fr;

    .mct-c-enroll-button-form__text {
      @extend .ds-u-margin-y--0;
      @extend .ds-u-text-align--left;
      @media screen and (min-width: $breakpoint-lg) {
        text-align: center;
      }
    }

    @media screen and (min-width: 1025px) {
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: $spacer-3 !important;
      margin-top: $spacer-7 !important;
    }

    .EnrollTransitionDialog > * {
      width: 100%;
    }
  }

  &__mobile_order_1 {
    order: 1;

    @media screen and (min-width: 1025px) {
      order: 0;
    }
  }

  &__mobile_order_2 {
    order: 2;

    @media screen and (min-width: 1025px) {
      order: 0;
    }
  }

  &__mobile_order_3 {
    order: 3;

    @media screen and (min-width: 1025px) {
      order: 0;
    }
  }

  &__copay {
    font-weight: 500;
  }

  .ds-c-skip-nav {
    left: -1000px;
    top: unset;
    bottom: 0;
    width: calc(100% - #{$spacer-4});

    &:focus {
      left: 0;
    }
  }

  &__benefits {
    @extend .ds-c-list;
    @extend .ds-c-list--bare;
    @extend .ds-u-display--block;

    li {
      @extend .ds-u-margin-bottom--0;
    }
  }

  @media print {
    .StarRating,
    &__body > div,
    &__flex_left {
      display: block !important;
    }
  }

  &.preview-mode {
    a:not(.preview-disable-exempt) {
      @extend .ds-u-color--base;
      pointer-events: none;
      text-decoration: none;
    }
  }

  .mct-c-benefit {
    $base: &;
    &:not(#{$base}--is-suppressed) {
      &:not(.PlanCard__copay) {
        @extend .ds-u-font-size--2xl;
        @extend .ds-u-font-weight--semibold;
        font-family: $font-family-montserrat;
      }
    }
  }
}
