.CCXPBase {
  position: relative;

  &__rotate {
    transform-origin: center;
    transform: rotate(180deg);
  }

  &__label {
    @extend .ds-text-heading--sm;
    @extend .ds-u-padding-x--0;
    @extend .ds-u-margin-top--0;
    text-transform: uppercase;
  }

  &__button {
    @media screen and (max-width: $media-width-lg) {
      width: 100%;
    }

    cursor: pointer;
    @extend .ds-u-border--0;
    font-family: $font-family-rubik;
    font-size: $font-size-md;
    @extend .ds-u-padding-y--1;
    @extend .ds-u-padding-x--2;
    border-radius: 4px;
    border: 2px solid $black-100 !important;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
    @extend .ds-u-xl-justify-content--center;
    @extend .ds-u-align-items--center;
    background-color: $color-white;
    color: $black-500 !important;
    outline: none;

    &--withValue {
      @extend .ds-u-fill--secondary;
      color: $color-white !important;

      svg > path {
        fill: $color-white;
      }
    }

    &--error {
      border: 3px solid $color-error !important;
    }

    &:focus {
      @extend .ds-u-focus;
    }
  }

  &__menu {
    @extend .ds-u-fill--white;
    @extend .ds-u-padding-x--3;
    @extend .ds-u-padding-y--2;
    display: none;
    box-shadow: 0 2px 3px 0 rgba(50, 50, 50, 0.23);
    border-radius: 6px;
    z-index: 2;
    min-width: 200px;
    box-sizing: content-box;

    &--wide {
      min-width: 300px;

      @media screen and (max-width: 300px) {
        min-width: 200px;
      }
    }

    &--widest {
      min-width: 352px; // 400px - 48px of padding;
    }

    &--open {
      @extend .ds-u-display--flex;
      @extend .ds-u-align-items--start;
      @extend .ds-u-flex-direction--column;
      position: absolute;
      left: 0;
      top: calc(100% + 8px);

      @media screen and (max-width: $media-width-lg) {
        width: 85%;
      }
    }
  }

  &__closeMenuButton {
    @extend .ds-u-fill--white;
    @extend .ds-u-border--0;
  }

  &__menuButtonContainer {
    align-self: flex-end;
  }

  &__menuButtonContainer:last-of-type {
    @extend .mct-u-width--full;
    @extend .ds-u-display--flex;
    @extend .ds-u-justify-content--between;
  }
}
