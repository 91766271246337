.MedigapPlanCard {
  @extend .ds-u-padding--1;
  @extend .ds-u-lg-padding--4;
  @extend .ds-u-margin-bottom--4;
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-wrap--wrap;
  @extend .ds-u-justify-content--between;

  @media print {
    box-shadow: none;
    border-radius: 0 !important;
    border-bottom: 1px solid $color-gray-lighter;
    padding: 0 !important;
    margin-top: 1em !important;
    margin-bottom: 0 !important;
    page-break-inside: avoid;

    &__header {
      padding: 0 !important;
    }

    &__body {
      display: block;
    }

    &__info .help-drawer-toggle {
      display: none !important;
    }

    &__data_section {
      display: inline-block;
      width: 30%;
      vertical-align: text-top;
    }

    &__divider {
      display: none !important;
    }
  }

  &__header {
    @extend .ds-u-padding-bottom--4;
    @extend .ds-u-md-padding-right--1;

    @media screen and (min-width: $media-width-md) {
      flex-basis: 50%;
    }

    @media screen and (min-width: $media-width-lg) {
      flex-basis: 60%;
    }

    @media screen and (min-width: $media-width-xl) {
      flex-basis: 70%;
    }

    h2 {
      @extend .ds-u-margin-top--0;
    }

    p {
      @extend .ds-u-margin--0;
    }
  }

  &__body {
    @extend .ds-l-row;
    width: 100%;
  }

  &__actions {
    @extend .ds-u-padding-bottom--4;

    @media screen and (max-width: $media-width-md) {
      order: 5;
      flex-basis: 100%;
    }

    a {
      white-space: nowrap;
      @extend .ds-u-display--block;
      @extend .ds-u-color--white;
    }

    > div {
      @extend .ds-u-display--flex;

      > *:not(:last-child) {
        @extend .ds-u-margin-right--2;
        @extend .ds-u-margin-bottom--0;
      }

      // in order for these to apply to iPad, we have to use max-width instead
      // of using ds-u-md-margin-right--2 which uses min-width
      @media screen and (max-width: $media-width-md) {
        display: block !important;

        > *:not(:last-child) {
          margin-right: 0 !important;
          margin-bottom: 16px !important;
        }
      }

      @media print {
        display: none !important;
      }
    }
  }

  &__section_heading {
    @extend .ds-u-text-transform--uppercase;
    font-family: $font-family-rubik;
    font-size: 16px;
    font-weight: 500 !important;
  }

  &__section_column {
    @extend .ds-l-col--12;
    @extend .ds-u-margin-bottom--3;
    @extend .ds-u-lg-margin-bottom--0;

    &--left {
      @extend .ds-l-lg-col--4;
    }
    &--middle {
      @extend .ds-l-lg-col--3;
      & > section:not(:last-child) {
        @extend .ds-u-margin-bottom--3;
        @extend .ds-u-lg-margin-bottom--5;
      }
    }
    &--right {
      @extend .ds-l-lg-col--3;
    }
  }

  &__line_item:not(:last-child) {
    @extend .ds-u-margin-bottom--1;
  }

  &__divider {
    @extend .ds-l-col--1;
    @extend .ds-u-display--none;
    @extend .ds-u-lg-display--block;

    &:after {
      @extend .ds-u-display--block;
      @extend .ds-u-fill--gray-lighter;
      content: "";
      height: 100%;
      left: 50%;
      position: relative;
      width: 1px;
    }
  }

  &__info {
    @extend .ds-u-display--flex;
    @extend .ds-u-align-items--baseline;

    img {
      height: 16px;
    }
  }

  &__benefit {
    @extend .ds-u-margin-bottom--1;

    svg {
      width: 14px;
    }
  }
}

.mct-disc-list {
  list-style-type: disc !important;
}
