@each $breakpoint in map-keys($breakpoints) {
  $value: map-get($breakpoints, $breakpoint);
  .mct-u-#{$breakpoint}-width--full {
    @media screen and (min-width: #{$value}) {
      width: 100% !important;
    }
  }
}

@each $breakpoint in map-keys($breakpoints) {
  $value: map-get($breakpoints, $breakpoint);
  .mct-u-#{$breakpoint}-width--auto {
    @media screen and (min-width: #{$value}) {
      width: auto !important;
    }
  }
}

.mct-u-height--full {
  height: 100%;
}

.mct-u-max-width--0 {
  max-width: 0 !important;
}

.mct-u-max-width--full {
  max-width: 100% !important;
}

.mct-u-min-width--full {
  min-width: 100% !important;
}

.mct-u-width--50 {
  width: 50% !important;
}

.mct-u-width--full {
  width: 100%;
}
