.CoverageWizardInfoPage {
  &__container {
    @media screen and (min-width: $media-width-md) {
      width: calc(#{$media-width-md} - 50px);
    }

    @media screen and (min-width: $media-width-lg) {
      width: calc(#{$media-width-lg} - 50px);
    }
  }

  &__banner {
    @extend .ds-u-padding-bottom--0;
    @extend .ds-u-padding-top--2;
  }

  &__changeSelection {
    @extend .ds-u-padding--0;
    @extend .ds-u-margin--0;
    @extend .ds-u-margin-left--1;
    @extend .ds-u-display--inline-block;
    @extend .ds-u-color--secondary-light;

    &:hover {
      @extend .ds-u-color--secondary;
    }
  }

  &__options {
    @extend .ds-u-md-display--flex;
    @extend .ds-u-md-flex-direction--row;
    @extend .ds-u-margin-bottom--7;
    @extend .ds-c-list--bare;
  }

  &__coverageIncludesList {
    @extend .ds-c-list;
    @extend .ds-c-list--bare;
    @extend .ds-u-display--block;

    li {
      @extend .ds-u-display--flex;

      span:nth-child(2) {
        @extend .ds-u-margin-left--1;
      }
    }
  }

  &__coverageInfo {
    @extend .ds-u-margin-top--3;
    @extend .ds-u-border-bottom--1;
    @extend .ds-u-margin-bottom--3;
  }

  &__coverageBlock {
    @media screen and (min-width: $media-width-md) {
      width: 49%;
    }
  }

  &__coverageIncludes {
    @extend .ds-u-margin-right--4;
    @extend .ds-u-margin-bottom--2;
  }

  &__coverageExplained {
    @extend .ds-u-padding-top--2;

    .Collapsible__trigger {
      width: 100%;

      & > span {
        @extend .ds-u-display--flex;
        @extend .ds-u-justify-content--between;
      }
    }
  }

  &__optionContainer {
    @media screen and (min-width: $media-width-md) {
      width: 49%;
    }

    &--bordered {
      @media screen and (min-width: $media-width-md) {
        border-right: 1px solid $color-border;
      }
    }
  }
}
