// Pharmacies Table
.PharmaciesTable {
  .mct-c-table__caption-col--2 {
    flex-basis: 25%;
  }
}

.CostByTier {
  &__changeButton {
    @extend .ds-u-margin-y--2;
    @extend .ds-u-lg-margin-left--2;
    @extend .ds-u-lg-margin-y--0;
    align-self: flex-end;
    @media screen and (min-width: $media-width-lg) {
      margin-bottom: 12px !important;
    }
  }

  &__filterContainer {
    @extend .mct-hide-print;
    @extend .ds-u-lg-display--flex;
    @extend .ds-u-lg-padding-x--2;
    @extend .ds-u-lg-padding-bottom--3;

    @media screen and (min-width: $media-width-lg) {
      border-left: 1px solid $color-gray-lighter;
      border-right: 1px solid $color-gray-lighter;
    }
  }
}

.PharmacyNetworkStatus {
  .mct-c-plan-details-pharmacy-type {
    display: grid;
    grid-template-columns: max-content;
    grid-template-areas:
      "status link"
      ". disclaimer";

    &__status {
      grid-area: status;
    }
    &__link {
      grid-area: link;
    }
    &__disclaimer {
      grid-area: disclaimer;
    }
    &__geography {
      font-family: $font-family-rubik;
    }
    &__distance {
      color: var(--color-gray);
    }
  }
}

#EstimatedTotalDrugAndPremiumCostTable {
  .when_will_you_enter_the_coverage_gap__heading {
    & > span {
      :first-child {
        @extend .ds-u-text-align--left;
        @extend .mct-c-table__header-title;
        color: $color-base;
      }
    }
  }
}

#yearly_drug_costs_by_pharmacy {
  scroll-margin-top: 60px;
}

.mct-c-costs-by-coverage-phase-table {
  &__paxlovid_footnote_mobile {
    @extend .ds-u-margin-top--1;
    font-family: $font-family-rubik !important;
  }
}
