.DrugCosts {
  line-height: 1;
  &__label {
    @extend .ds-u-font-weight--bold;
  }

  &__list {
    @extend .ds-u-padding--0;
    @extend .ds-u-margin-y--2;
  }
}

.DrugItem {
  @extend .ds-l-row;
  @extend .ds-u-padding-bottom--2;

  &__mark {
    @extend .ds-l-col--1;
    text-align: right;
  }
}

.PharmacyItem {
  @extend .ds-u-display--flex;
  @extend .ds-u-flex-direction--column;
  @extend .ds-u-justify-content--between;
  @extend .ds-u-padding-bottom--2;
}
