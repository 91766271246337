.ScrollTable {
  overflow-x: auto;
  @media screen and (max-width: $media-width-sm) {
    margin-right: -16px;
  }
}

.ScrollTable__MobileHelp {
  @extend .ds-u-margin-y--2;
  @extend .ds-u-padding--1;
  @extend .ds-u-fill--warn-lightest;
  caption-side: top;
  text-align: left;
  width: 100%;

  img {
    @extend .ds-u-margin-x--1;
  }

  .ScrollTable__MobileViewMore {
    @extend .ds-u-margin-left--2;
    @extend .ds-u-display--inline-block;
  }

  @media screen and (min-width: $media-width-lg) {
    display: none;
  }
}
