.mct-c-help-drawer {
  z-index: 200;
  // z-index fix
  position: relative;
  li {
    @extend .ds-u-margin-bottom--2;
  }

  p:last-child {
    @extend .ds-u-margin-bottom--0;
  }

  h3,
  &__header .ds-u-text--lead {
    outline: 0;

    @media screen and (max-width: $media-width-md) {
      line-height: 32px;
    }
  }
  .ds-c-drawer__footer {
    // remove default footer styling
    display: none;
  }
}
