.DrugAutocomplete {
  &__addDrugButton {
    padding: 14px;

    @media screen and (min-width: $media-width-md) {
      margin-bottom: 32px;
    }
  }

  &__actions {
    @extend .ds-u-margin-top--6;
    @extend .ds-u-padding-top--4;
    @extend .ds-u-border-top--2;
  }

  &__browseDrugsLink {
    @extend .ds-u-margin-top--2;
    @extend .ds-u-md-display--flex;

    @media screen and (min-width: $media-width-md) {
      .AtoZDrugList {
        width: 350px;
      }
    }
  }

  &__autocomplete {
    .ds-c-button--transparent {
      color: $color-secondary-light;
    }

    .ds-c-autocomplete__list-item--active {
      @extend .ds-u-fill--secondary;
      cursor: pointer;
    }
  }

  .ds-c-dialog__actions {
    @extend .ds-u-display--flex;
    @extend .ds-u-flex-wrap--wrap;
    column-gap: $spacer-2;
    row-gap: $spacer-1;
    .ds-c-button {
      @extend .mct-u-width--full;
      @extend .mct-u-md-width--auto;
    }
  }
}
