.MedigapChangeLocation__link {
  & > button {
    @extend .ds-u-padding--0;
    @extend .ds-u-margin-bottom--6;
  }
}

.ChangeLocationStatePicker {
  @extend .ds-u-padding-top--2;

  &__subtitle {
    font-family: $font-family-rubik;
    font-size: $font-size-md;
    font-weight: 400;
    margin-bottom: $spacer-1;
  }
}
