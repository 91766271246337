.mct-c-summary-page-pharmacy-card {
  position: relative;
  padding: $spacer-3;
  margin-right: $spacer-3;
  width: 355px;
  @media screen and (max-width: $media-width-sm) {
    width: auto;
    margin: 0 0 $spacer-3;
  }
  @media print {
    border: none;
    box-shadow: none;
    margin: $spacer-3 0 $spacer-3 0;
    padding: 0;
  }
  &__name {
    margin-top: 0;
  }
  &__label {
    position: absolute;
    top: -12px;
    left: -12px;
    background-color: white;
    padding-bottom: $spacer-1;
    height: $spacer-3;
    @media print {
      background-color: transparent;
      left: 0;
      // this value helps the labels from crashing into their pharmacy titles
      top: -28px;
    }
  }
}
