.ds-c-button {
  $btn: &;
  font-weight: $font-weight-normal;
  transition: $animation-speed-2 $animation-ease-in-out-expo;

  // Set initial inline padding to 0 on responsive buttons
  // Resets to auto at specific breakbpoints
  &.mct-u-width--full {
    box-sizing: border-box;
    &.mct-u-sm-width--auto,
    &.mct-u-md-width--auto {
      @extend .ds-u-padding-x--1;
    }
    &.mct-u-sm-width--auto {
      @extend .ds-u-sm-padding-x--3;
    }
    &.mct-u-md-width--auto {
      @extend .ds-u-md-padding-x--3;
    }
  }

  &--on-dark {
    // Taken from CMSDS default dark Button state styles
    &#{$btn}--solid {
      &:not(:disabled, .ds-c-button-disabled, [aria-disabled="true"]) {
        &:hover,
        &:hover:focus {
          background-color: $button-dark__color--hover;
          border: $button__border-width solid $button-dark__color--hover;
          color: $button__color;
        }
        &:active,
        &:active:focus {
          background-color: $button-dark__color--active;
          border: $button__border-width solid $button-dark__color--active;
          color: $button__color;
        }
      }
    }
  }

  // @see https://github.com/CMSgov/design-system/issues/2303
  // An issue has been raised on CMSDS to add this qualification to the existing
  // declarations that target `[aria-disabled]` insead of `[aria-disabled]:not([aria-disabled="false"])`
  &,
  #{$btn}:visited {
    &[aria-disabled="false"] {
      color: currentColor;
      cursor: pointer;
    }
  }
}

.ds-c-button--alternate:disabled {
  &:active,
  &:hover,
  &:focus {
    background-color: $color-gray-lighter !important;
    color: $color-gray-dark !important;
    @extend .ds-c-button--disabled;
  }
}
