// @TODO - Figure out whether this is being used anywhere? The class name doesn't
// show up in any code in this repo

.BeneMenu {
  display: none;

  @media screen and (min-width: $media-width-sm) {
    display: block;
  }

  .Collapsible__trigger {
    cursor: pointer;
  }

  &__Title {
    @extend .ds-u-fill--primary;
    @extend .ds-u-color--white;
    @extend .ds-u-margin--0;
    @extend .ds-u-padding-x--2;
    padding-top: 26px;
    padding-bottom: 26px;
  }

  &__Container {
    @extend .ds-u-fill--gray-lightest;
    @extend .ds-u-text-align--left;
    box-shadow: 0px 2px 6px darken($color-gray-lighter, 20%);

    // Required to make the 'dropdown' part float above the main content of the page
    position: absolute;
    z-index: 1;

    margin-top: 4px;

    .Collapsible__contentInner {
      @extend .ds-u-padding--2;
    }
  }

  &__InnerName {
    @extend .ds-u-display--block;
    @extend .ds-u-color--primary;
    @extend .ds-u-font-weight--semibold;
  }
}

.MobileMenuBeneInfo {
  hr:last-child {
    @extend .ds-u-margin-bottom--0;
  }

  @media screen and (min-width: $media-width-sm) {
    display: none;
  }
}
