.mct-c-featured-plan {
  background-color: $color-secondary-lightest;
  margin: 0 -#{$spacer-7};
  padding: $spacer-4 $spacer-7;
  @extend .ds-u-md-display--flex;

  .mct-c-summary-page-plan-card--find-plans {
    @extend .ds-u-margin-right--0;
    @extend .ds-u-margin-top--4;
    @extend .ds-u-md-margin-top--0;
  }

  .mct-c-summary-page-bedap-alert {
    @extend .ds-u-margin-right--0;
    max-width: $media-width-sm;
    width: auto;
  }

  &--with-plan,
  &--with-error {
    column-gap: $spacer-4; // @TODO - confirm
  }

  &--no-plan {
    .mct-c-featured-plan__plan-details {
      @media screen and (min-width: $media-width-lg) {
        max-width: 66.66%;
      }
    }
  }

  &--with-error {
    @extend .ds-u-lg-display--flex;
    .mct-c-summary-page-plan-card.mct-c-summary-page-plan-card--find-plans {
      @extend .ds-u-md-margin-top--4;
      @extend .ds-u-lg-margin-top--0;
      flex-shrink: 1;
    }
  }

  &__header {
    @extend .ds-u-margin--0;
    &-link {
      color: $color-secondary;
      text-decoration-color: $color-secondary;
      &:hover {
        color: $color-secondary-darker;
        text-decoration-color: $color-secondary-darker;
      }
      &:visited {
        color: $color-secondary;
        text-decoration-color: $color-secondary;
        &:hover {
          color: $color-secondary-darker;
          text-decoration-color: $color-secondary-darker;
        }
      }
    }
  }

  &__status-message {
    @extend .ds-u-font-size--base;
    @extend .ds-u-margin-top--0;
    @extend .ds-u-leading--heading;
    &--primary {
      @extend .ds-u-font-weight--bold;
      @extend .ds-u-margin-bottom--1;
    }
    &--secondary {
      @extend .ds-u-font-weight--normal;
    }
  }

  .mct-c-summary-page-plan-card {
    align-self: flex-start;
    flex-shrink: 0.6;
  }
}
